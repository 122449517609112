import classNames from "classnames";


export default function HeaderCard({ label, value, icon, action = null, actionName = null }) {
    const item = { icon }
    return (
        <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
                <div className="absolute bg-indigo-500 rounded-md p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {label}
                </p>
            </dt>
            <dd className={classNames("ml-16 flex items-baseline", actionName ? "pb-6 sm:pb-7" : "pb-0 sm:pb-1")}>
                <p className="text-2xl font-semibold text-gray-900">
                    {value}
                </p>

                {actionName && (
                    <div onClick={action} className={classNames("absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6", action ? 'cursor-pointer' : '')}>
                        <div className="text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                {' '}
                                {actionName}
                            </a>
                        </div>
                    </div>
                )}
            </dd>
        </div>
    )
}