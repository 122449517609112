import classNames from "classnames"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDoubleLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import { Fragment } from "react"
import { generatePagination } from "Utility/generator"

export function WhileFormError({ form, attribute, children }) {
    return (
        form.error?.response?.data?.errors[attribute] && (
            children({ message: form.error?.response?.data?.errors[attribute] })
        )
    )
}

export function WhileFormErrors({ form, attributes = [], operator = 'or', children }) {
    return (
        (
            operator === 'or' ?
                (attributes.filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined).length) :
                attributes.filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined).length === attributes.length
        ) ? (
            children({
                messages: attributes
                    .filter(attribute => form.error?.response?.data?.errors[attribute] !== undefined)
                    .map(attribute => form.error?.response?.data?.errors[attribute])
            })
        ) : null
    )
}

export function classNamesWhileError(form, attribute, className, ...restClassNames) {
    return classNames(...restClassNames, form.error?.response?.data?.errors[attribute] ? className : "")
}


export function Pagination({ pagination, onPaginationChange }) {
    const { currentPage, itemCount, pageSize, pageCount } = pagination
    const pages = generatePagination(currentPage, pageCount)

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    onClick={() => onPaginationChange({ page: currentPage - 1, show: pageSize })}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Sebelumnya
                </button>
                <button
                    onClick={() => onPaginationChange({ page: currentPage + 1, show: pageSize })}
                    disabled={currentPage === pageCount}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Selanjutnya
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Menampilkan halaman <span className="font-medium">{((currentPage * pageSize) - pageSize + 1)}</span> dari <span className="font-medium">
                            {(currentPage * pageSize)}
                        </span> dari {' '}
                        <span className="font-medium">{itemCount}</span> semua data
                    </p>
                </div>
                <div className="flex flex-row gap-1">
                    <div>
                        <Listbox value={pageSize} onChange={(value) => onPaginationChange({ page: currentPage, show: value })}>
                            {({ open }) => (
                                <>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                            <span className="flex items-center">
                                                <span className="ml-3 block truncate">{pageSize}</span>
                                            </span>
                                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute  mt-1 bottom-14 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                {[5, 10, 20, 50, 100].map((size) => (
                                                    <Listbox.Option
                                                        key={size}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={size}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <div className="flex items-center">
                                                                    <span
                                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block')}
                                                                    >
                                                                        {size}
                                                                    </span>
                                                                </div>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                    <div>

                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button
                                onClick={() => onPaginationChange({ page: currentPage - 1, show: pageSize })}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Sebelumnya</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            {
                                pages.map(page =>
                                    <button
                                        key={page}
                                        onClick={() => onPaginationChange({ page: page, show: pageSize })}
                                        aria-current="page"
                                        className={classNames(
                                            "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                                            currentPage === page ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                                        )}
                                    >
                                        {page}
                                    </button>
                                )
                            }
                            <button
                                onClick={() => onPaginationChange({ page: currentPage + 1, show: pageSize })}
                                disabled={currentPage === pageCount}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Selanjutnya</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
