import { useState } from "react"
import { TreeStructure } from "@phosphor-icons/react";
import { ActionDialog } from "UI/Component/Dialog";
import classNames from "classnames";
import { InputText } from "UI/Component/Input";
import { useCreateEmployeePosition } from "../../Data/API";
import { transformResponseError, transformResponseToSingle } from "Utility/formatter";
import ComboboxEmployeePositionGroup from "App/Organization/EmployeePositionGroup/UI/Block/ComboboxEmployeePositionGroup";
import { Button } from "@tremor/react";

export default function DialogCreateEmployeePosition({ open, onClose, onConfirm }) {
    const [name, setName] = useState('')
    const [employeePositionGroupID, setEmployeePositionGroupID] = useState(null)

    const createEmployeePosition = useCreateEmployeePosition({onSuccess : (data)=>{
        const employeePosition = transformResponseToSingle(data)
        onConfirm(employeePosition)

    }})
    const error = transformResponseError(createEmployeePosition.error)

    return (
        <ActionDialog
            icon={TreeStructure}
            open={open} onClose={onClose}
            title="Tambah Posisi Baru"
            onConfirm={() => createEmployeePosition.mutateAsync({name, employeePositionGroupID})}
            confirm={(onActionConfirm) => (
                <Button type="submit" onClick={() => onActionConfirm()} loading={createEmployeePosition.isLoading} loadingText="Menyimpan">
                    Simpan
                </Button>
            )}
        >
            <div className="min-h-[200px]">
                <div className="mt-4 grid grid-cols-1 md:grid-cols-1 gap-2">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Nama Posisi</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <InputText
                                value={name}
                                onChange={value => setName(value)}
                                errorMessage={error['body.name']}
                            />
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Grup Posisi</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <ComboboxEmployeePositionGroup
                                value={employeePositionGroupID}
                                onChange={value => setEmployeePositionGroupID(value)}
                                errorMessage={error['body.employeePositionGroupID']}
                            />
                        </dd>
                    </div>
                </div>
            </div>
        </ActionDialog>
    )
}