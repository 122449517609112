import * as IconSolid from '@heroicons/react/24/solid'
import { useCallback, useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import moment from 'moment'
import { Link, useSearchParams } from 'react-router-dom'
import { useBrowseAccount } from 'Data/Authentication/account'
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { Pagination } from 'UI/Component'
import TableLoader from 'UI/Component/TableLoader'
import RoleSelection from './RoleSelection'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { humanizeDate } from 'Utility/humanize'
import CreateNewAccount from './CreateNewAccount'

const now = moment()

export function stateForm(form) {
    return form.deleted ? 'archive' :
        (
            now.isAfter(moment(form.startDate)) && now.isBefore(moment(form.endDate)) ?
                'active' : 'expired'

        )
}

export default function BrowseAccount() {
    const [actionState, setActionState] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryKeyword, setQueryKeyword] = useState(searchParams.get('keyword'))
    const page = searchParams.get('page')
    const show = searchParams.get('show')
    const role = searchParams.get('role')
    const keyword = searchParams.get('keyword')

    const browseAccount = useBrowseAccount({ expands: ['role'], keyword, currentPage: searchParams.get('page'), role, pageSize: show })
    const pagination = transformResponseToPagination(browseAccount.data)
    const accounts = transformResponseToList(browseAccount.data)

    const search = useCallback((event) => {
        setSearchParams({keyword: queryKeyword})
        event.preventDefault()
    })

    useEffect(() => {
        browseAccount.refetch()
    }, [page, show, role])

    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Modul Access
                    </h1>
                    <button className='text-indigo-600' href="https://account.kirimin.com">https://account.kirimin.com</button>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4">
                    <button
                        onClick={browseAccount.refetch}
                        type="button"
                        className="order-1 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-0 sm:ml-0"
                    >
                        Muat Ulang <IconSolid.ArrowPathIcon className={classNames(
                            "ml-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 border-2 p-1 rounded-full",
                            browseAccount.isFetching ? 'animate-spin' : ''
                        )} />
                    </button>
                    <button
                        onClick={() => setActionState('create-new-account')}
                        className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-1 sm:ml-3"
                    >
                        Create new Account
                    </button>
                </div>
            </div>
            {/* Pinned projects */}
            <div className="px-4 mt-6 sm:px-6 lg:px-8">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">Based on Role</h2>
                <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
                    <button onClick={() => setSearchParams({ role: '' })}>
                        <RoleSelection color="bg-gray-500" role="" title="All" />
                    </button>
                    <button onClick={() => setSearchParams({ role: 'Super-Administrator' })}>
                        <RoleSelection color="bg-indigo-500" role="Super-Administrator" title="Super Administrator" />
                    </button>
                    <button onClick={() => setSearchParams({ role: 'Administrator' })}>
                        <RoleSelection color="bg-red-500" role="Administrator" title="Administrator" />
                    </button>
                    <button onClick={() => setSearchParams({ role: 'Employee' })}>
                        <RoleSelection color="bg-green-500" role="Employee" title="Employee" />
                    </button>
                    <button onClick={() => setSearchParams({ role: 'Courier' })}>
                        <RoleSelection color="bg-orange-500" role="Courier" title="Courier" />
                    </button>
                </ul>
            </div>

            <div className='mt-5 mx-4'>
                <form onSubmit={search}>
                    <div className="flex-1 flex justify-center lg:justify-end ">
                        <div className="w-full px-2 lg:px-6 ">
                            <label htmlFor="search" className="sr-only">
                                Search Truck
                            </label>
                            <div className="relative text-indigo-200 focus-within:text-gray-400">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-indigo-800" aria-hidden="true" />
                                </div>
                                <input
                                    value={queryKeyword}
                                    className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-900 placeholder-indigo-800 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                                    placeholder="Search for account email"
                                    type="search"
                                    onChange={({ target: { value } }) => setQueryKeyword(value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='m-10'>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    E-mail
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                >
                                    Timestamp
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Access
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Aksi</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {browseAccount.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :
                                accounts.map((account) =>
                                    <tr key={account.ID}>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <Link to={`/account/${account.ID}`} className="flex-shrink-0 group block">
                                                <div className="flex items-center">
                                                    <div>
                                                        <img
                                                            className="inline-block h-9 w-9 rounded-full"
                                                            src={`https://ui-avatars.com/api/?name=${account.email}`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                            {account.email.substring(0, account.email.indexOf("@"))}
                                                            <span className="text-xs font-medium text-gray-400 group-hover:text-gray-900">
                                                                {account.email.substring(account.email.indexOf("@"))}
                                                            </span>
                                                        </p>
                                                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td className='space-y-2'>
                                            <div className="flex items-center space-x-2">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="text-gray-900 text-sm font-medium">
                                                    Created on <time dateTime="2020-12-02">{humanizeDate(account.created)}</time>
                                                </span>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="text-gray-900 text-sm font-medium">
                                                    Last login <time dateTime="2020-12-02">{humanizeDate(account.updated)}</time>
                                                </span>
                                            </div>
                                        </td>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            {account.role.superAdministrator && (
                                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-indigo-500', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">Super Administrator</span>
                                                </button>
                                            )}
                                            {account.role.administrator && (
                                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-red-500', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">Administrator</span>
                                                </button>
                                            )}
                                            {account.role.employee && (
                                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">Employee</span>
                                                </button>
                                            )}
                                            {account.role.courier && (
                                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-orange-500', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">Courier</span>
                                                </button>
                                            )}
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <Link to={`/account/${account.ID}`}>
                                                <span className='text-indigo-600 hover:text-indigo-900 hidden md:block'>
                                                    Ubah
                                                </span>
                                                <div className='bg-indigo-600 rounded-full p-2 flex justify-center items-center md:hidden'>
                                                    <IconSolid.PencilSquareIcon className='w-4 h-4 text-white ' />
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>
                                )

                            }
                        </tbody>
                    </table>
                    <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show })} />
                </div>
            </div>

            <CreateNewAccount
                open={actionState === 'create-new-account'}
                onClose={() => setActionState(null)}
                onConfirm={() => {
                    browseAccount.refetch()
                    setActionState(null)
                }}
            />
        </DashboardLayout>

    )
}
