/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import * as IconSolid from '@heroicons/react/24/solid'
import { useHeadlessCalender } from 'UI/Component/Calender'
import classNames from 'classnames'
import moment from 'moment'

export default function CalenderSelect({ value, onChange, selectView = () => null, onChangeDays = null }) {
    const { days, setCursorDate, cursorDate } = useHeadlessCalender({ onChangeDays })
    const valueAsMoment = value ? moment(value, 'YYYY-MM-DD') : null
    return (
        <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
            <div className="md:pr-14">
                <div className="flex items-center">
                    <h2 className="flex-auto font-semibold text-gray-900">{cursorDate.format('MMMM YYYY')}</h2>
                    <button
                        type="button"
                        onClick={() => setCursorDate(cursorDate.clone().subtract(1, 'month'))}
                        className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Bulan sebelumnya</span>
                        <IconSolid.ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        onClick={() => setCursorDate(cursorDate.clone().add(1, 'month'))}
                        className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Bulan Setelahnya</span>
                        <IconSolid.ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    <div>Sen</div>
                    <div>Sel</div>
                    <div>Rab</div>
                    <div>Kam</div>
                    <div>Jum</div>
                    <div>Sab</div>
                    <div>Min</div>
                </div>
                <div className="mt-2 grid grid-cols-7 text-sm">
                    {days.map((day, dayIdx) => (
                        <div key={day.date} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
                            <button
                                type="button"
                                onClick={() => onChange(day.date)}
                                className={classNames(
                                    value === day.date && 'text-white',
                                    value !== day.date && day.isToday && 'text-indigo-600',
                                    value !== day.date && !day.isToday && day.isCurrentMonth && 'text-gray-900',
                                    value !== day.date && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                                    value === day.date && day.isToday && 'bg-indigo-600',
                                    value === day.date && !day.isToday && 'bg-gray-900',
                                    value !== day.date && 'hover:bg-gray-200',
                                    (value === day.date || day.isToday) && 'font-semibold',
                                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                                )}
                            >
                                <time dateTime={day.date}>{day.date.split('-').pop().replace(/^0/, '')}</time>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {valueAsMoment === null ? null :
                selectView(valueAsMoment)
            }

        </div>
    )
}