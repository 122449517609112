import DashboardLayout from "UI/Layout/DashboardLayout"
import { IconOutline, IconSolid } from "UI/Component/Icon"
import { Button, Card, ColGrid, Metric, Text } from "@tremor/react"
import DeliveryStatus from "../../UI/Component/DeliveryStatus"
import { humanizeCurrency } from "Utility/humanize"
import { TabViewRoutes } from "UI/Component/TabView"
import DetailDeliverySummary from "./DetailDeliverySummary"
import { DetailDeliveryProvider, useDetailDeliveryContext } from "./context"

export default function DetailDelivery() {
    return (
        <DetailDeliveryProvider>
            <DetailDeliveryContextualize />
        </DetailDeliveryProvider>
    )
}

function DetailDeliveryContextualize() {
    const { id, detailDelivery, delivery } = useDetailDeliveryContext()

    if (detailDelivery.isLoading && !delivery) {
        return null
    }

    return (
        <DashboardLayout>
            <div className="bg-white shadow mb-10">
                <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                    <div className="py-6 md:flex md:items-center md:justify-between lg:border-gray-200">
                        <div className="flex-1 min-w-0">
                            {/* Profile */}
                            <div className="flex items-center">
                                <div className='flex-1 ml-5 md:ml-3'>
                                    <div className="flex items-center">
                                        <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                            Pengiriman #{id}
                                        </h1>
                                    </div>
                                    <dl className="mt-0 flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap">
                                        <dt className="sr-only">Oleh</dt>
                                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                            <IconOutline.UserCircleIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hidden md:block"
                                                aria-hidden="true"
                                            />
                                            {delivery.member.name}
                                        </dd>
                                        <dt className="sr-only">Telepon</dt>
                                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                            <IconSolid.DevicePhoneMobileIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hidden md:block"
                                                aria-hidden="true"
                                            />
                                            {delivery.member.phone.number}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <Button icon={IconSolid.ArrowPathRoundedSquareIcon} loading={detailDelivery.isLoading} onClick={() => detailDelivery.refetch()}>
                                Muat Ulang
                            </Button>
                            <Button color="rose" icon={IconSolid.XCircleIcon} loading={detailDelivery.isLoading} onClick={() => detailDelivery.refetch()}>
                                Batalkan
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='items-center hidden md:block mb-10'>
                <div className='flex justify-center flex-col mx-auto max-w-5xl gap-6'>
                    <ColGrid numColsSm={2} numColsLg={3} gapX="gap-x-6" gapY="gap-y-6">
                        <Card>
                            <Text>Biaya</Text>
                            <Metric>{humanizeCurrency(delivery.price)}</Metric>
                        </Card>
                        <Card>
                            <Text>Status</Text>
                            <Metric>
                                <DeliveryStatus status={delivery.state.status} />
                            </Metric>
                        </Card>
                        <Card>
                            <Text>Kurir</Text>
                            <Metric>
                                {!delivery.route.assigned ? null : delivery.route.assigned.courier.name}
                            </Metric>
                        </Card>
                    </ColGrid>
                </div>
            </div>
            <div className='mx-6'>
                <TabViewRoutes
                    basePath={`/delivery/${id}`}
                    routes={[
                        { to: ``, label: 'Informasi', icon: { solid: IconSolid.IdentificationIcon, outline: IconOutline.IdentificationIcon }, element: DetailDeliverySummary },
                        { to: `/map`, label: 'Peta', icon: { solid: IconSolid.MapIcon, outline: IconOutline.MapIcon }, element: DetailDeliverySummary },


                    ]}
                />
            </div>
        </DashboardLayout>
    )
}