import { useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import { GoogleMap, MarkerClustererF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { transformResponseToList } from 'Utility/formatter';
import { LocationInformation } from 'UI/Component/Map';
import { humanizeNumber } from 'Utility/humanize';
import haversine from 'haversine-distance'
import { MapPinIcon } from '@heroicons/react/24/outline';
import TableLoader from 'UI/Component/TableLoader';
import { Link, useNavigate } from 'react-router-dom';
import { usePinPointCourier } from 'App/Organization/Courier/Data/api';


let loopID = null;

const ZOOM_NEAR = 16.23
const ZOOM_DEFAULT = 7.92
const CENTER_DEFAULT = { lat: -5.5985397, lng: 106.3509951 }
const clutererOptions = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

export default function LiveTrackTruck() {
    const navigate = useNavigate()
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY // ,
        // ...otherOptions
    })
    const pinPointCourier = usePinPointCourier({ expands: [] })
    const pinPointCouriers = transformResponseToList(pinPointCourier.data)

    const [center, setCenter] = useState(CENTER_DEFAULT)
    const [zoom, setZoom] = useState(ZOOM_DEFAULT)

    useEffect(() => {
        loopID = setInterval(() => {
            pinPointCourier.refetch()
        }, 15 * 1000);

        return () => {
            clearInterval(loopID)
        }
    }, [])

    useEffect(() => {
        if (isLoaded) {
            pinPointCourier.refetch()
        }
    }, [isLoaded])

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    const focus = (newCenter) => {
        setCenter({ lat: newCenter.latitude, lng: newCenter.longitude })
        setZoom(ZOOM_NEAR)
    }

    return (
        <DashboardLayout className="">
            <div className='relative flex' style={{ height: '800px' }}>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%' }}
                        center={center}
                        zoom={zoom}
                    >
                        <MarkerClustererF
                            onClick={(cluster) => {
                                console.log('cluster', cluster)
                                console.log('cluster', cluster.getMarkers())
                            }}
                            options={clutererOptions}
                        >
                            {(clusterer) => pinPointCouriers.map(pinPointCourier => {
                                return (
                                    <MarkerF
                                        key={pinPointCourier.ID}
                                        clusterer={clusterer}
                                        onDblClick={() => navigate(`/courier/${pinPointCourier.ID}`)}
                                        icon={'https://cdn.iconscout.com/icon/free/png-32/courier-shipping-logistic-delivery-transport-supply-vehicle-12-20617.png'}
                                        position={{ lat: pinPointCourier.lastLocation.latitude, lng: pinPointCourier.lastLocation.longitude }}
                                    />
                                )
                            })}
                        </MarkerClustererF>
                    </GoogleMap>
                )}
            </div>

            <div className="px-4 sm:px-6 lg:px-8 py-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Monitor Lokasi</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Keadaan Lokasi terakhir dari setiap Driver beserta dengan keadaan pekerjaan. Meliputi juga lokasi tambak dan pabrik
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {Math.abs(zoom - ZOOM_DEFAULT) <= 1 || haversine({ latitude: center.lat, longitude: center.lng }, { latitude: CENTER_DEFAULT.lat, longitude: CENTER_DEFAULT.lng }) <= 100 ? null :
                            <button
                                onClick={() => {
                                    setZoom(ZOOM_DEFAULT)
                                    setCenter(CENTER_DEFAULT)
                                }}
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Tampilan Default
                            </button>

                        }
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full">
                                    <thead className="bg-white">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Kurir
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Lokasi Terakhir
                                            </th>
                                          
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Locate</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {pinPointCourier.isLoading && (
                                            <TableLoader
                                                rowSize={10}
                                                columnSize={4}
                                            />
                                        )}
                                        <tr className="border-t border-gray-200">
                                            <th
                                                colSpan={5}
                                                scope="colgroup"
                                                className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                            >
                                                Dalam Pekerjaan
                                            </th>
                                        </tr>
                                        {pinPointCouriers.filter(courier => courier.onGoing !== null).map((courier, index) => (
                                            <CourierRow
                                                index={index}
                                                key={courier.ID}
                                                courier={courier}
                                                onFocus={focus}
                                                center={center}
                                                zoom={zoom}
                                            />
                                        ))}

                                        {pinPointCourier.isLoading && (
                                            <TableLoader
                                                rowSize={10}
                                                columnSize={4}
                                            />
                                        )}
                                        <tr className="border-t border-gray-200">
                                            <th
                                                colSpan={5}
                                                scope="colgroup"
                                                className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                            >
                                                Tidak dalam Pekerjaan
                                            </th>
                                        </tr>
                                        {pinPointCouriers.filter(courier => courier.onGoing === null).map((courier, index) => (
                                            <CourierRow
                                                index={index}
                                                key={courier.ID}
                                                courier={courier}
                                                onFocus={focus}
                                                center={center}
                                                zoom={zoom}
                                            />
                                        ))}

                                        {pinPointCourier.isLoading && (
                                            <TableLoader
                                                rowSize={10}
                                                columnSize={4}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>

    )
}


function CourierRow({ courier, index, onFocus, center, zoom }) {
    const isNear = zoom >= ZOOM_NEAR && haversine({ latitude: center.lat, longitude: center.lng }, courier.lastLocation) <= 100

    return (
        <tr className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <Link to={`/courier/${courier.ID}`} className='flex flex-row gap-2 items-center'>
                    <div className={classNames("rounded-md w-10 h-10 flex justify-center items-center shadow-2xl", courier.onGoing ? 'bg-error' : 'bg-success')}>
                        <span className='text-white'>{courier.ID}</span>
                    </div>
                    <div>
                        <span> {courier.name}</span>
                    </div>
                </Link>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {courier.lastLocation && (
                    <LocationInformation
                        object={courier}
                    />
                )}
            </td>

            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                {courier.lastLocation && (
                    <button disabled={isNear} onClick={() => onFocus(courier.lastLocation)} className={classNames(isNear ? "text-gray-600 hover:text-gray-900" : "text-indigo-600 hover:text-indigo-900")}>
                        {isNear ?
                            'Sudah di lokasi' :
                            'Lihat di peta'
                        }
                    </button>
                )}
            </td>
        </tr>
    )
}