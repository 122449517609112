import { Listbox, Menu, Transition } from '@headlessui/react'
import * as IconSolid from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useBrowseEmployeeFile, useDeleteEmployeeFile, useUploadEmployeeFile } from "Data/Organization/employee"
import { Fragment, useMemo } from 'react'
import { FileIcon, defaultStyles } from "react-file-icon"
import { useSearchParams } from 'react-router-dom'
import { transformErrorNetwork, transformResponseToList } from "Utility/formatter"
import { useArrayState } from 'Utility/hook'
import { humanizePercentage, humanizeRelativeFromDate } from "Utility/humanize"
import { useDetailEmployeeContext } from "./context"
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast'
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

const sorts = [
    { label: 'A-Z', handle: (itemA, itemB) => 1 * itemA.name.localeCompare(itemB.name) },
    { label: 'Z-A', handle: (itemA, itemB) => -1 * itemA.name.localeCompare(itemB.name) },
    { label: 'Oldest file', handle: (itemA, itemB) => itemA.timestamp.modified - itemB.timestamp.modified },
    { label: 'Newest file', handle: (itemA, itemB) => itemB.timestamp.modified - itemA.timestamp.modified },
]

export default function DetailEmployeeBrowseFile() {
    const { id } = useDetailEmployeeContext()
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop: drops => upload(drops),
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/vnd.ms-powerpoint': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
        }
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const [filterTypes, addFilterType, removeFilterType, existFilterType] = useArrayState([], (itemA, itemB) => itemA === itemB)
    const selectedSortLabel = searchParams.get('sort') ?? sorts[0].label

    const selectedSort = sorts.find(sort => sort.label === selectedSortLabel)
    const browseEmployeeFile = useBrowseEmployeeFile(id, {})
    const rawFiles = transformResponseToList(browseEmployeeFile.data)
    const availableTypes = rawFiles.map(file => file.type.extension).filter((value, index, self) => self.indexOf(value) === index)

    const sortedFiles = [...rawFiles].sort(selectedSort.handle)
    const files = filterTypes.length === 0 ? sortedFiles : [...sortedFiles].filter(file => filterTypes.includes(file.type.extension))

    const [uploads, addUpload, removeUpload, existUpload, updateUpload, resetUpload] = useArrayState([], (itemA, itemB) => itemA.name === itemB.name)
    const [deletes, addDelete, removeDelete, existDelete] = useArrayState([], (itemA, itemB) => itemA.name === itemB.name)
    const [uploadeds, addUploaded, removeUploaded, existUploaded, updateUploaded, resetUploaded] = useArrayState([], (itemA, itemB) => itemA.name === itemB.name)
    const uploadEmployeeFile = useUploadEmployeeFile(id, {
        onSuccess: (response) => {
            addUploaded(response)
            browseEmployeeFile.refetch()
        },
        onError: (error) => {
            toast.error(transformErrorNetwork(error))
        }
    })
    const deleteEmployeeFile = useDeleteEmployeeFile(id, {
        onSuccess: () => {
            browseEmployeeFile.refetch()
        }
    })

    const upload = (files) => {
        files.map(file => {
            addUpload(file)
            uploadEmployeeFile.mutate({ file })
        })
    }


    return (
        <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
            <div className='flex justify-between gap-10'>
                <div className='flex flex-row items-center gap-6'>
                    <div className="w-72">
                        <Listbox value={selectedSort} onChange={value => setSearchParams({ sort: value.label })}>
                            <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">{selectedSort.label}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <IconSolid.ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {sorts.map((sort) => (
                                            <Listbox.Option
                                                key={sort.label}
                                                className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`}
                                                value={sort}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                }`}
                                                        >
                                                            {sort.label}
                                                        </span>
                                                        {selected &&
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                <IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        }
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                    <div className='flex gap-3'>
                        {availableTypes.map(availableType =>
                            <span onClick={() => existFilterType(availableType) ? removeFilterType(availableType) : addFilterType(availableType)} className={classNames(
                                "flex rounded-full items-center py-1 justify-center pl-2.5 text-sm font-medium cursor-pointer",
                                existFilterType(availableType) ? 'bg-indigo-100 text-indigo-700 pr-1' : 'bg-white border-dashed border border-indigo-700 pr-2.5'
                            )}>
                                {availableType}
                                {existFilterType(availableType) && (
                                    <button
                                        type="button"
                                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                    >
                                        <span className="sr-only">Hapus {availableType}</span>
                                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                        </svg>
                                    </button>
                                )}
                            </span>
                        )}
                    </div>
                </div>

                {uploads.length === 0 ? null :
                    uploads.length !== uploadeds.length ?
                        <div className='w-full'>
                            <div className="">
                                <p className="text-sm font-medium text-gray-900">Menunggah...</p>
                                <div className="mt-1" aria-hidden="true">
                                    <div className="bg-gray-200 rounded-full overflow-hidden w-full">
                                        <div className="h-2 bg-indigo-600 rounded-full" style={{ width: humanizePercentage(uploadeds.length / uploads.length) }} />
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='w-full'>
                            <div className="rounded-md bg-green-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-green-800">Selesai diunggah</p>
                                    </div>
                                    <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <button
                                                onClick={() => {
                                                    resetUpload()
                                                    resetUploaded()
                                                }}
                                                type="button"
                                                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                                            >
                                                <span className="sr-only">Tutup</span>
                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                <div>

                </div>
            </div>


            <div {...getRootProps()} className="mt-10 flex flex-col justify-center items-center">
                <div>
                    <div className="max-w-lg flex justify-center px-36 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                    <span>Upload a file</span>
                                    <input {...getInputProps()} />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                    </div>
                </div>
            </div>

            <ul
                role="list"
                className="my-10 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
            >
                {files.map((file) => (
                    <li key={file.name} className={classNames(
                        "relative border p-5 rounded-lg shadow-sm hover:shadow-xl",
                        existDelete(file) ? 'opacity-30' : ''
                    )}>

                        <div className="flex flex-row justify-between ">
                            <div>

                            </div>

                            <Menu as="div" className="flex-shrink-0">
                                <Menu.Button className=" bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    <IconSolid.EllipsisVerticalIcon className='h-4 w-4' />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={file.URL}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Unduh
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Lihat
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">

                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => {
                                                            addDelete(file)
                                                            deleteEmployeeFile.mutate({ name: `${file.name}.${file.type.extension}` })
                                                        }}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Hapus
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>


                        <div className="flex flex-col gap-8 items-center justify-center cursor-pointer">
                            <div className="w-16 h-16">
                                <FileIcon
                                    extension={file.type.extension}
                                    {...defaultStyles[file.type.extension]}
                                />
                            </div>
                            <h1 className="font-semibold flex w-full justify-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                {file.name}.{file.type.extension}
                            </h1>
                        </div>


                        <div className="relative my-5">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-between">
                                <span className="bg-white px-2 text-gray-500">
                                    <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path
                                            fill="#6B7280"
                                            fillRule="evenodd"
                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                                <span className="bg-white px-2 text-gray-500">
                                    <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path
                                            fill="#6B7280"
                                            fillRule="evenodd"
                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="flex flex-row justify-between">
                            <dd>
                                <dl className="font-semibold">File size:</dl>
                                <dt className="text-gray-500 text-xs">{file.sizeReadable}</dt>
                            </dd>
                            <dd>
                                <dl className="font-semibold text-right">Last modified:</dl>
                                <dt className="text-gray-500 text-xs text-right">{humanizeRelativeFromDate(file.timestamp.modifiedReadable)}</dt>
                            </dd>
                        </div>
                    </li>
                ))}
            </ul>



        </section >
    )
}