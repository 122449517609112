import { useMutation, useQuery } from "react-query"
import { requestPost, requestGet } from "Provider/RestClient"

export function useMutationLogin({ onSuccess, onError, role = 'Super-Administrator' }) {
    const mutation = useMutation(({ id, password }) => requestPost('/authentication/access', { username: id, password }, { Role: role }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}


export function useLoggedUser({ onSuccess, onError, enabled = true }) {
    const data = useQuery('/authentication/access', () => requestGet('/authentication/access'), {
        enabled: enabled,
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null,
    })
    return data
}