
import { useEffect, useRef } from 'react'

function useEffectOnce(effect) {
    const destroyFunction = useRef()
    const calledOnce = useRef(false)
    const renderAfterCalled = useRef(false)

    if (calledOnce.current) {
        renderAfterCalled.current = true
    }
    
    useEffect(() => {
        if (calledOnce.current) {
            return
        }

        calledOnce.current = true
        destroyFunction.current = effect()

        return () => {
            if (!renderAfterCalled.current) {
                return
            }

            if (destroyFunction.current) {
                destroyFunction.current()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useEffectOnce