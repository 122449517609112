import { useCallback } from "react"
import { useState, useEffect, useRef } from "react";

export const DEFAULT_MAP = { lat: -5.5985397, lng: 106.3509951 }
export const DEFAULT_ZOOM = 6.92

export function useMapState(center = DEFAULT_MAP, zoom = DEFAULT_ZOOM) {
    const [mapCenter, setMapCenter] = useState(center)
    const [mapZoom, setMapZoom] = useState(zoom)
    const locateMap = useCallback((center) => {
        setMapCenter(center)
    }, [])

    return { mapCenter, mapZoom, setMapCenter, setMapZoom, locateMap }
}

export function useArrayState(initial = [], compareTo = null) {
    const [state, setState] = useState(initial)

    const add = ((newEntry) => {
        setState(previous => [...previous, newEntry])
    })
    const remove = ((removedEntry) => {
        const newState = [...state].filter((item, index) => compareTo ? !compareTo(item, removedEntry) : item !== removedEntry)
        setState(newState)
    })
    const update = ((searchEntry, updateItem) => {
        const newState = [...state].map((item, index) => (compareTo ? compareTo(item, searchEntry) : item === searchEntry) ? ({ ...item, ...updateItem }) : item)
        setState(newState)
    })
    const exist = ((searchEntry) => {
        return state.find((item, index) => compareTo ? compareTo(item, searchEntry) : item === searchEntry)
    })
    const reset = (() => {
        setState(initial)
    })

    return [state, add, remove, exist, update, reset, setState]
}

// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export default function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}


export function useDebouncedCallback(callback, wait) {
    // track args & timeout handle between calls
    const argsRef = useRef();
    const timeout = useRef();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    // make sure our timeout gets cleared if
    // our consuming component gets unmounted
    useEffect(() => cleanup, []);

    return function debouncedCallback(...args) {
        // capture latest args
        argsRef.current = args;

        // clear debounce timer
        cleanup();

        // start waiting again
        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, wait);
    };
}


export function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null)

    const copy = async text => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported')
            return false
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text)
            setCopiedText(text)
            return true
        } catch (error) {
            console.warn('Copy failed', error)
            setCopiedText(null)
            return false
        }
    }

    return [copiedText, copy]
}