import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseEmployee({
    sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1,
    haveAccess = null, driveTruck = null, isInActive = null, keyword,
    onSuccess
}) {
    const path = `/organization/employee`
    const data = useQuery([
        ...(path.split('/')),
        sort, order, pageSize, currentPage,
        haveAccess, driveTruck, isInActive, keyword,
    ], () => requestGet(path, {
        sort, order, pageSize, currentPage: currentPage || 1, expand: expands.join(','),
        driveTruck, haveAccess, isInActive, keyword,
    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDetailEmployee(ID, { expands, onSuccess = () => null }) {
    const path = `/organization/employee/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onSuccess })
}

export function useCreateEmployee({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        name, birthDate, birthPlace,
        domicileAddress, phone,
        nationalIdentityNumber, employeeIdentityNumber,
        employeePositionID, maritalStatusID, educationLevelID
    }) => requestPost(`/organization/employee`, {
        name, birthDate, birthPlace,
        domicileAddress, phone,
        nationalIdentityNumber, employeeIdentityNumber,
        employeePositionID, maritalStatusID, educationLevelID
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useUpdateEmployee(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        name, birthPlace, birthDate,
        domicileAddress, phone,
        nationalIdentityNumber, employeeIdentityNumber,
        employeePositionID, maritalStatusID, educationLevelID,
        isInActive,
    }) => requestPost(`/organization/employee/${ID}`, {
        name, birthPlace, birthDate,
        domicileAddress, phone,
        nationalIdentityNumber, employeeIdentityNumber,
        employeePositionID, maritalStatusID, educationLevelID,
        isInActive,
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useBrowseEmployeeFile(ID, { onSuccess }) {
    const path = `/organization/employee/${ID}/file`
    const data = useQuery([
        ...(path.split('/')),
    ], () => requestGet(path, {

    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUploadEmployeeFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        file,
    }) => requestPost(`/organization/employee/${ID}/file`, {
        file
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useDeleteEmployeeFile(ID, { onSuccess = () => null, onError }) {
    const path = `/organization/employee/${ID}/file`
    const mutation = useMutation(({ name }) => requestDelete(`${path}/${name}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}