import * as IconSolid from '@heroicons/react/24/solid'
import { useCallback, useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { objectFilterNull, transformResponseError, transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { Pagination } from 'UI/Component'
import TableLoader from 'UI/Component/TableLoader'
import ComboboxEmployeePositionGroup from 'App/Organization/EmployeePositionGroup/UI/Block/ComboboxEmployeePositionGroup'
import { Button, Icon } from '@tremor/react'
import { ArrowClockwise, ArrowsClockwise, LineSegment, LineSegments, PlusSquare, Textbox, TrashSimple } from '@phosphor-icons/react'
import toast from "react-hot-toast"
import { useBrowseEmployeePositionGroup, useDeleteEmployeePositionGroup } from '../../Data/API'
import DialogCreateEmployeePositionGroup from '../../UI/Block/DialogCreateEmployeePositionGroup'
import DialogUpdateEmployeePositionGroup from '../../UI/Block/DialogUpdateEmployeePositionGroup'

export default function BrowseEmployeePositionGroup() {
    const navigate = useNavigate()
    const [actionState, setActionState] = useState({ action: null })
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryKeyword, setQueryKeyword] = useState(searchParams.get('keyword'))
    const action = searchParams.get('action')
    const page = searchParams.get('page')
    const show = searchParams.get('show')
    const employeePositionGroupGroupID = searchParams.get('employeePositionGroupGroupID')
    const keyword = searchParams.get('keyword')
    const browseEmployeePositionGroup = useBrowseEmployeePositionGroup({ expands: [], currentPage: searchParams.get('page'), keyword, employeePositionGroupGroupID, pageSize: show })
    const deleteEmployeePositionGroup = useDeleteEmployeePositionGroup({
        onSuccess: () => {
            toast.success('Berhasil menghapus')
            browseEmployeePositionGroup.refetch()
        },
        onError: (errorNative) => {
            const error = transformResponseError(errorNative)
            for (const errorAttribute in error) {
                toast.error(error[errorAttribute].join(', '))
            }

        }
    })

    const pagination = transformResponseToPagination(browseEmployeePositionGroup.data)
    const employeePositionGroupGroups = transformResponseToList(browseEmployeePositionGroup.data)

    const search = useCallback((event) => {
        setSearchParams({ keyword: queryKeyword })
        event.preventDefault()
    })

    useEffect(() => {
        browseEmployeePositionGroup.refetch()
    }, [page, show, employeePositionGroupGroupID])


    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Struktur Posisi Jabatan Karyawan
                    </h1>
                    <span className='text-indigo-600'>
                        Pencariaan / Manajemen
                    </span>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4 gap-1.5">
                    <Link
                        to="?action=create-employee-position-group"
                    >
                        <Button loading={action === 'create-employee-position-group'} icon={PlusSquare}>
                            Grup Posisi Baru
                        </Button>
                    </Link>
                    <Link
                        to="/employee-position"
                    >
                        <Button variant='secondary' icon={LineSegment}>
                            Manajemen Posisi
                        </Button>
                    </Link>
                </div>
            </div>

            <div className='mt-5 mx-4'>
                <form onSubmit={search}>
                    <div className="flex-1 flex justify-center lg:justify-end ">
                        <div className="w-full px-2 lg:px-6 ">
                            <label htmlFor="search" className="sr-only">
                                Pencarian Posisi
                            </label>
                            <div className='flex gap-1.5'>
                                <div className='flex flex-1 flex-col'>
                                    <div className="relative text-indigo-200 focus-within:text-gray-400">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-indigo-800" aria-hidden="true" />
                                        </div>
                                        <input
                                            value={queryKeyword ?? ''}
                                            className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-900 placeholder-indigo-800 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                                            placeholder="Ketik nama grup posisi untuk mencari"
                                            type="search"
                                            onChange={({ target: { value } }) => setQueryKeyword(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            <div className='mx-10 mb-10'>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Nama Posisi
                                </th>

                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Hak Manajer HRD
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Hak Staf Operasional
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Aksi</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {browseEmployeePositionGroup.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :
                                employeePositionGroupGroups.map((employeePositionGroup) =>
                                    <tr key={employeePositionGroup.ID} className={classNames(employeePositionGroup.inActiveDate ? 'opacity-40 hover:opacity-100' : '')}>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <div className="flex-shrink-0 group block">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                            {employeePositionGroup.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>


                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            <Icon
                                                icon={IconSolid.CheckCircleIcon}
                                                variant={employeePositionGroup.isHumanResourceManager ? "solid" : "outlined"}
                                                tooltip={employeePositionGroup.isHumanResourceManager ? "Memiliki Hak Akses ini" : "Tidak memiliki Hak Akses ini"}
                                            />
                                        </td>

                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            <Icon
                                                icon={IconSolid.CheckCircleIcon}
                                                variant={employeePositionGroup.isOperationalStaff ? "solid" : "outlined"}
                                                tooltip={employeePositionGroup.isOperationalStaff ? "Memiliki Hak Akses ini" : "Tidak memiliki Hak Akses ini"}
                                            />
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <div className='flex items-end justify-end gap-1.5'>
                                                <Button onClick={() => setActionState({ action: 'update-employee-position-group', data: employeePositionGroup })} color='blue' variant='secondary' size='xs' icon={Textbox} >
                                                    Ubah
                                                </Button>

                                                <Button onClick={() => deleteEmployeePositionGroup.mutateAsync({ ID: employeePositionGroup.ID })} size='xs' color='rose' icon={TrashSimple} loading={deleteEmployeePositionGroup.isLoading && deleteEmployeePositionGroup.variables?.ID === employeePositionGroup.ID} loadingText="Menghapus..." >
                                                    Hapus
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show, employeePositionGroupGroupID })} />
                </div>
            </div>

            <DialogCreateEmployeePositionGroup
                open={action === 'create-employee-position-group'}
                onClose={() => navigate(-1)}
                onConfirm={() => {
                    browseEmployeePositionGroup.refetch()
                    navigate(-1)
                }}
            />
            <DialogUpdateEmployeePositionGroup
                open={actionState.action === 'update-employee-position-group'}
                update={actionState?.data}
                onClose={() => setActionState({ action: null })}
                onConfirm={() => {
                    browseEmployeePositionGroup.refetch()
                    setActionState({ action: null })
                }}
            />
        </DashboardLayout>
    )
}
