import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"
import { defaultObject } from "Utility/formatter"

export function useBrowseCourier(inputParameters) {
    const parameters = defaultObject(inputParameters, { sort: 'ID', order: 'desc', pageSize: 20, currentPage: 1, keyword: '', expands: [], memberID: null, onSuccess: null })
    const path = `/organization/courier`
    const data = useQuery([
        ...(path.split('/')),
        parameters.sort, parameters.order, parameters.pageSize, parameters.currentPage, parameters.keyword,
        parameters.expands,
        parameters.memberID
    ],
        () => requestGet(path, {
            sort: parameters.sort, order: parameters.order, currentPage: parameters.currentPage, pageSize: parameters.pageSize, keyword: parameters.keyword,
            expand: parameters.expands.join(','),
            memberID: parameters.memberID,
        }), {
        onSuccess: (response) => parameters.onSuccess ? parameters.onSuccess(response) : null,
    })
    return data
}

export function usePinPointCourier(inputParameters) {
    const parameters = defaultObject(inputParameters, { sort: 'ID', order: 'desc', pageSize: 20, currentPage: 1, keyword: '', expands: [], memberID: null, onSuccess: null })
    const path = `/organization/courier/pin-point`
    const data = useQuery([
        ...(path.split('/')),
        parameters.sort, parameters.order, parameters.pageSize, parameters.currentPage, parameters.keyword,
        parameters.expands,
        parameters.memberID
    ],
        () => requestGet(path, {
            sort: parameters.sort, order: parameters.order, currentPage: parameters.currentPage, pageSize: parameters.pageSize, keyword: parameters.keyword,
            expand: parameters.expands.join(','),
            memberID: parameters.memberID,
        }), {
        onSuccess: (response) => parameters.onSuccess ? parameters.onSuccess(response) : null,
    })
    return data
}

export function useDetailCourier(ID, inputParameters) {
    const parameters = defaultObject(inputParameters, { expands: [], onSuccess: () => null })
    const path = `/organization/courier/${ID}`
    return useQuery(path, () => requestGet(path, { expand: parameters.expands.join(',') }), { onSuccess: parameters.onSuccess })
}

export function useCreateCourier({ onSuccess = () => null, onError }) {
    const path = `/organization/courier`
    const mutation = useMutation(({ name }) => requestPost(path, { name }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useUpdateCourier({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, name }) => requestPost(`/organization/courier/${ID}`, { name }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useDeleteCourier({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID }) => requestDelete(`/organization/courier/${ID}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}




export function useBrowseCourierFile(ID, { onSuccess }) {
    const path = `/organization/courier/${ID}/file`
    const data = useQuery([
        ...(path.split('/')),
    ], () => requestGet(path, {

    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUploadCourierFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        file,
    }) => requestPost(`/organization/courier/${ID}/file`, {
        file
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useDeleteCourierFile(ID, { onSuccess = () => null, onError }) {
    const path = `/organization/courier/${ID}/file`
    const mutation = useMutation(({ name }) => requestDelete(`${path}/${name}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}