import { useDetailEmployee, useUpdateEmployee } from "Data/Organization/employee"
import { useBrowseEmployeeBankAccount } from "Data/Organization/employee-bank-account"
import { useBrowseEmployeeSalary } from "Data/Organization/employee-salary"
import { createContext, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { transformResponseToList, transformResponseToSingle } from "Utility/formatter"

const DetailEmployeeContext = createContext()

export function DetailEmployeeProvider({ children }) {
    const { id } = useParams()
    const [actionState, setActionState] = useState(null)
    const [selectedActionObject, selectActionObject] = useState(null)
    const [updateSalary, setUpdateSalary] = useState(null)
    const detailEmployee = useDetailEmployee(id, { expands: ['access', 'position', 'education', 'marital', 'bankAccounts', 'salary', 'trucks', 'contacts'] });
    const browseEmployeeBankAccount = useBrowseEmployeeBankAccount({ sort: 'bankID', order: 'asc', employeeID: id })
    const browseEmployeeSalary = useBrowseEmployeeSalary({ sort: 'ID', order: 'desc' })
    const employee = transformResponseToSingle(detailEmployee.data)
    const employeeSalaries = transformResponseToList(browseEmployeeSalary.data)
    const employeeBankAccounts = transformResponseToList(browseEmployeeBankAccount.data)
    const updateEmployee = useUpdateEmployee(id, {
        onSuccess: () => {
            detailEmployee.refetch()
        }
    })

    const shared = {
        id,
        selectedActionObject, selectActionObject,
        actionState, setActionState,
        updateSalary, setUpdateSalary,
        detailEmployee, updateEmployee, browseEmployeeSalary, browseEmployeeBankAccount,
        employee, employeeSalaries, employeeBankAccounts
    }
    return (
        <DetailEmployeeContext.Provider value={shared}>
            {children}
        </DetailEmployeeContext.Provider>
    )
}

export function useDetailEmployeeContext() {
    return useContext(DetailEmployeeContext)
}
