import { useGeolocated } from "react-geolocated";
import AttendanceLog from "./AttendanceLog";
import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import classNames from "classnames";
import { useAuth } from "App";

export default function AttendancePanel({ }) {
    const { user } = useAuth()

    return (
        <div className='flex justify-center my-10'>
            <div className='grid grid-cols-1 md:grid-cols-2 flex-1 gap-3'>
                <div className='flex-1'>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6 relative">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Jam Masuk</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait jam kerja masuk anda</p>
                            <div className={classNames("absolute right-5 top-5  rounded-md p-3", user.role.employee.roleEntity.todayAttendance.in ? 'bg-success' : 'border border-gray-600 bg-white')}>
                                <IconOutline.ArrowDownTrayIcon className={classNames("h-5 w-5 ", user.role.employee.roleEntity.todayAttendance.in ? 'text-white' : 'text-gray-600')} />
                            </div>
                        </div>
                        <div className={classNames("border-t border-gray-200 px-4 py-5 sm:p-0", user.role.employee.roleEntity.todayAttendance.in ? '' : '')}>
                            <AttendanceLog
                                type="in"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6 relative">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Jam Keluar</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait jam kerja keluar anda</p>
                            <div className={classNames("absolute right-5 top-5  rounded-md p-3", user.role.employee.roleEntity.todayAttendance.out ? 'bg-success' : 'border border-gray-600 bg-white')}>
                                <IconOutline.ArrowUpTrayIcon className={classNames("h-5 w-5 ", user.role.employee.roleEntity.todayAttendance.out ? 'text-white' : 'text-gray-600')} />
                            </div>
                        </div>
                        <div className={classNames("border-t border-gray-200 px-4 py-5 sm:p-0", user.role.employee.roleEntity.todayAttendance.out ? '' : '')}>
                            <AttendanceLog
                                type="out"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}