import { createContext, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { transformResponseToSingle } from "Utility/formatter"
import { useDetailCourier, useUpdateCourier } from "../../Data/api"

const DetailCourierContext = createContext()

export function DetailCourierProvider({ children }) {
    const { id } = useParams()
    const [actionState, setActionState] = useState(null)
    const [selectedActionObject, selectActionObject] = useState(null)
    const [updateSalary, setUpdateSalary] = useState(null)
    const detailCourier = useDetailCourier(id, { expands: ['statistic'] });
    const courier = transformResponseToSingle(detailCourier.data)

    const updateCourier = useUpdateCourier(id, {
        onSuccess: () => {
            detailCourier.refetch()
        }
    })

    const shared = {
        id,
        selectedActionObject, selectActionObject,
        actionState, setActionState,
        updateSalary, setUpdateSalary,
        detailCourier, updateCourier,
        courier
    }
    return (
        <DetailCourierContext.Provider value={shared}>
            {children}
        </DetailCourierContext.Provider>
    )
}

export function useDetailCourierContext() {
    return useContext(DetailCourierContext)
}
