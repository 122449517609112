import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import { useCallback, useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import { Link, useSearchParams } from 'react-router-dom'
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { Pagination } from 'UI/Component'
import TableLoader from 'UI/Component/TableLoader'
import { humanizeDate, humanizeRelativeFromDate } from 'Utility/humanize'
import useDebounce from 'Utility/hook'
import { LocationInformation } from 'UI/Component/Map'
import CalenderSelect from './CalenderSelect'
import moment from 'moment'
import { useBrowseEmployeeAttendanceLog } from 'Data/Organization/employee-attendance-log'
import { authenticationProvider } from 'Provider/Authentication'
import Lottie from "lottie-react";

export default function BrowseEmployeeAttendanceLog() {
    const [actionState, setActionState] = useState(null)
    const [queryKeyword, setQueryKeyword] = useState('')
    const debounceQueryKeyword = useDebounce(queryKeyword, 500);
    const [searchParams, setSearchParams] = useSearchParams();
    const date = searchParams.get('date') ?? moment().format('YYYY-MM-DD')
    const page = searchParams.get('page')
    const show = searchParams.get('show')
    const keyword = searchParams.get('keyword')
    const state = searchParams.get('state')

    const startDate = moment(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
    const endDate = moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')

    const browseAttendanceLog = useBrowseEmployeeAttendanceLog({ expands: ['employee'], keyword, currentPage: searchParams.get('page'), pageSize: show, date: date, sort: 'date', order: 'desc', keyword: debounceQueryKeyword })
    const pagination = transformResponseToPagination(browseAttendanceLog.data)
    const attendanceLogs = transformResponseToList(browseAttendanceLog.data)

    const search = useCallback((event) => {
        browseAttendanceLog.refetch()
        event.preventDefault()
    })

    useEffect(() => {
        browseAttendanceLog.refetch()
    }, [page, show, keyword])

    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Sistem Presensi
                    </h1>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4">
                    <span className="relative z-0 inline-flex shadow-sm rounded-md">

                        <button
                            type="button"
                            onClick={() => browseAttendanceLog.refetch()}
                            className={classNames(
                                "relative inline-flex items-center px-4 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
                                authenticationProvider.role() !== 'Super-Administrator' ? 'rounded-md' : 'rounded-l-md'
                            )}
                        >
                            <IconSolid.ArrowPathIcon className={classNames("-ml-1 mr-2 h-5 w-5 ", browseAttendanceLog.isLoading ? 'animate-spin text-info' : 'text-gray-400')} aria-hidden="true" />
                            Muat ulang
                        </button>
                        {authenticationProvider.role() !== 'Super-Administrator' ? null :
                            <a
                                href={`${process.env.REACT_APP_PROXY_BASE_URL_API ? process.env.REACT_APP_PROXY_BASE_URL_API : process.env.REACT_APP_BASE_URL_API}/organization/employee-attendance-log/export?startDate=${startDate}&endDate=${endDate}&token=${authenticationProvider.token()}`}
                                className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <IconOutline.ArrowDownTrayIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </a>
                        }

                    </span>
                </div>
            </div>

            <div className='m-10'>
                <div className='mb-10'>
                    <div>
                        <div className="flex items-center justify-between">
                            <h2 className="text-sm font-medium text-gray-900">Tanggal</h2>

                        </div>

                        <CalenderSelect
                            value={date}
                            onChange={(newDate) => setSearchParams({ date: newDate })}
                            selectView={(cursorDate) => (
                                <section className="mt-12 md:mt-0 md:pl-14 hidden">
                                    <h2 className="font-semibold text-gray-900">
                                        Absensi terakhir <time dateTime={cursorDate.format('YYYY-MM-DD')}>{cursorDate.format('D MMMM, YYYY')}</time>
                                    </h2>
                                    <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
                                        {/* {meetings.map((meeting) => (
                                                <li
                                                    key={meeting.id}
                                                    className="group flex items-center space-x-4 rounded-xl py-2 px-4 focus-within:bg-gray-100 hover:bg-gray-100"
                                                >
                                                    <img src={meeting.imageUrl} alt="" className="h-10 w-10 flex-none rounded-full" />
                                                    <div className="flex-auto">
                                                        <p className="text-gray-900">{meeting.name}</p>
                                                        <p className="mt-0.5">
                                                            <time dateTime={meeting.startDatetime}>{meeting.start}</time> -{' '}
                                                            <time dateTime={meeting.endDatetime}>{meeting.end}</time>
                                                        </p>
                                                    </div>
                                                    <Menu as="div" className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100">
                                                        <div>
                                                            <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
                                                                <span className="sr-only">Open options</span>
                                                                <IconSolid.EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>

                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                                                <div className="py-1">
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <a
                                                                                href="#"
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-2 text-sm'
                                                                                )}
                                                                            >
                                                                                Edit
                                                                            </a>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <a
                                                                                href="#"
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-2 text-sm'
                                                                                )}
                                                                            >
                                                                                Cancel
                                                                            </a>
                                                                        )}
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </li>
                                            ))} */}
                                    </ol>
                                </section>
                            )}
                        />
                    </div>
                </div>
                {authenticationProvider.role() !== 'Super-Administrator' ? null :
                    <div>
                        <form onSubmit={search}>
                            <div className="flex-1 flex justify-center lg:justify-end ">
                                <div className="w-full px-2 lg:px-6 ">
                                    <label htmlFor="search" className="sr-only">
                                        Cari Karyawan
                                    </label>
                                    <div className="relative text-indigo-200 focus-within:text-gray-400">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-indigo-800" aria-hidden="true" />
                                        </div>
                                        <input
                                            value={queryKeyword}
                                            className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-900 placeholder-indigo-800 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                                            placeholder="Cari nama karyawan"
                                            type="search"
                                            onChange={({ target: { value } }) => setQueryKeyword(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Karyawan
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Tipe
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Tanggal
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Jam
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Keterangan
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Lokasi
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Aksi</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {browseAttendanceLog.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :

                                attendanceLogs.length === 0 ?

                                    <tr className="divide-x divide-gray-200">
                                        <td colSpan={6} className="whitespace-nowrap p-1 text-sm text-gray-500 ">
                                            <div className='flex flex-col mb-10 items-center justify-center'>
                                                <div className='w-56'>
                                                    <Lottie animationData={require('Asset/Lottie/empty.json')} loop={true} />
                                                </div>
                                                <h1 class="text-5xl font-bold">Tidak Ada Data</h1>
                                                <p class="py-6">Tidak ada data pada filter ini. Coba ganti bulan atau hapus beberapa filter yang diterapkan.</p>
                                            </div>
                                        </td>
                                    </tr> :
                                    attendanceLogs.map((attendanceLog) =>
                                        <tr key={attendanceLog.ID}>
                                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                <Link to={`/employee/${attendanceLog.employee.ID}`} className="flex-1 min-w-0">
                                                    <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                                        <div className="flex-shrink-0">
                                                            <div class={classNames("avatar placeholder", !attendanceLog.employee.todayAttendance.out && !attendanceLog.employee.todayAttendance.in ? '' : ((attendanceLog.employee.todayAttendance.out && attendanceLog.employee.todayAttendance.in) ? 'online' : 'offline'))}>
                                                                <div class="bg-neutral-focus text-neutral-content rounded-full w-8">
                                                                    <span class="text-xs">{attendanceLog.employee.ID}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                            <a href="#" className="focus:outline-none">
                                                                {/* Extend touch target to entire panel */}
                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                <p className="text-sm font-medium text-gray-900">{attendanceLog.employee.name}</p>
                                                                <p className="text-sm text-gray-500 truncate">{attendanceLog.employee.employeeIdentityNumber}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                {attendanceLog.type}
                                            </td>
                                            <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                {humanizeDate(attendanceLog.date)}
                                            </td>
                                            <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                <div>
                                                    <div>{attendanceLog.time}</div>
                                                    <div className='text-xs text-gray-400'>{humanizeRelativeFromDate(`${attendanceLog.date} ${attendanceLog.time}`)}</div>
                                                </div>
                                            </td>
                                            <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                <div className='font-light'>
                                                    {attendanceLog.note}
                                                </div>
                                            </td>
                                            <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                <LocationInformation
                                                    object={attendanceLog}
                                                    selector={object => object.location}
                                                    isHistory={false}
                                                />
                                            </td>

                                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">

                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                    {authenticationProvider.role() !== 'Super-Administrator' ? null :
                        <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show, state })} />
                    }
                </div>
            </div>
        </DashboardLayout>

    )
}
