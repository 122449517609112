import { Badge } from "@tremor/react";
import { IconOutline, IconSolid } from "UI/Component/Icon";

export default function DeliveryStatus({ status }) {
    return (
        <div>
            <Badge
                text={({
                    'pending': 'Pending',
                    'open': 'Open',
                    'active': 'Active',
                    'finish': 'Finish',
                    'cancel': 'Cancel'
                }[status.value])}
                color={({
                    'pending': 'gray',
                    'open': 'cyan',
                    'active': 'green',
                    'finish': 'blue',
                    'cancel': 'red'
                }[status.value])}
                icon={({
                    'pending': IconOutline.BanknotesIcon,
                    'open': IconSolid.CursorArrowRippleIcon,
                    'active': IconSolid.FireIcon,
                    'finish': IconSolid.BanknotesIcon,
                    'cancel': IconSolid.XMarkIcon
                }[status.value])}
            />
        </div>
    )
}