import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import Sandbox from './Static/Sandbox'
import Home from './Static/Home';
import Login from './Authentication/Credential/Login';
import NotFound from './Static/NotFound';
import BrowseAccount from './Authentication/Account/BrowseAccount';
import DetailAccount from './Authentication/Account/DetailAccount';
import BrowseEmployee from './Organization/Employee/BrowseEmployee';
import DetailEmployee from './Organization/Employee/DetailEmployee';
import LiveTrackTruck from './Monitor/LiveTrackTruck';
import BrowseTruckBrand from './Inventory/Truck/BrowseTruckBrand';
import BrowseTruckModel from './Inventory/Truck/BrowseTruckModel';

import { createContext, Fragment, useContext, useState } from 'react';
import { authenticationProvider } from 'Provider/Authentication';
import useEffectOnce from 'Utility/useEffectOnce';
import { useLoggedUser } from 'Data/Authentication/access';
import { Toaster } from 'react-hot-toast';
import CreateEmployee from './Organization/Employee/CreateEmployee';
import UpdateEmployee from './Organization/Employee/UpdateEmployee';
import MillageReport from './Information/Report/MillageReport';
import BrowseEmployeeAttendanceLog from './Organization/EmployeAttendanceLog/BrowseEmployeeAttendanceLog';

import CourierRoute from './Organization/Courier/Route';
import DeliveryRoute from './Transaction/Delivery/Route';


import '@tremor/react/dist/esm/tremor.css';
import BrowseEmployeePosition from './Organization/EmployeePosition/Route/BrowseEmployeePosition';
import BrowseEmployeePositionGroup from './Organization/EmployeePositionGroup/Route/BrowseEmployeePositionGroup';

const queryClient = new QueryClient()
const AuthContext = createContext()

function AuthProvider({ children }) {
  const dataLoggedUser = useLoggedUser({
    enabled: false,
    onError: (error) => signOut(),
    onSuccess: ({ data: response }) => {
      const data = response.data
      signIn(data.account, authenticationProvider.token())
    }
  })
  let [user, setUser] = useState(false);

  let signIn = (loggedInUser, token) => {
    return authenticationProvider.in(token, () => {
      setUser(loggedInUser)
    })
  }

  let signOut = () => {
    return authenticationProvider.out(() => {
      setUser(null)
    })
  }

  let refreshAuth = () => {
    if (authenticationProvider.authenticated()) {
      dataLoggedUser.refetch()
    } else {
      setUser(null)
    }
  }


  useEffectOnce(function init() {
    if (authenticationProvider.authenticated()) {
      dataLoggedUser.refetch()
    } else {
      console.log("AAASSSUUU")
      setUser(null)
    }

  })
  return <AuthContext.Provider value={{ user, signIn, signOut, refreshAuth }}>{children}</AuthContext.Provider>
}

function useAuth() {
  return useContext(AuthContext)
}

function RequireAuthentication({ children }) {
  let auth = useAuth()
  let location = useLocation()

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

function Router() {
  const auth = useAuth()

  if (auth.user === false) {
    return null
  }

  return (
    <Fragment>
      {
        auth.user ?
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="account">
                <Route index element={<BrowseAccount />} />
                <Route path=':id' element={<DetailAccount />} />
              </Route>
              <Route path="employee">
                <Route index element={<BrowseEmployee />} />
                <Route path='create' element={<CreateEmployee />} />
                <Route path=':id/update' element={<UpdateEmployee />} />
                <Route path=':id/*' element={<DetailEmployee />} />
              </Route>
              <Route path="employee-position">
                <Route index element={<BrowseEmployeePosition />} />
                <Route path="group">
                  <Route index element={<BrowseEmployeePositionGroup />} />
                </Route>
              </Route>

              <Route path="courier">
                <Route index element={<CourierRoute.BrowseCourier />} />
                <Route path='create' element={<CourierRoute.CreateCourier />} />
                <Route path=':id/*' element={<CourierRoute.DetailCourier />} />
              </Route>
              <Route path="employee-attendance-log">
                <Route index element={<BrowseEmployeeAttendanceLog />} />
              </Route>
              <Route path="delivery">
                <Route index element={<DeliveryRoute.BrowseDelivery />} />
                <Route path=':id/*' element={<DeliveryRoute.DetailDelivery />} />
              </Route>
              <Route path="report">
                <Route path='millage' element={<MillageReport />} />
              </Route>
              <Route path="live">
                <Route index element={<LiveTrackTruck />} />
              </Route>
              <Route path="sandbox" element={<Sandbox />} />
              <Route path="coba" element={
                <RequireAuthentication>
                  <Sandbox />
                </RequireAuthentication>
              } />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes> :
          <Routes>
            <Route path="/">
              <Route index element={<Login />} />
              <Route path="sandbox" element={<Sandbox />} />
              <Route path="coba" element={
                <RequireAuthentication>
                  <Sandbox />
                </RequireAuthentication>
              } />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
      }
    </Fragment>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
          <Toaster />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}


export { useAuth }
export default App;
