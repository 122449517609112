import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseEmployeePositionGroup({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword, onSuccess }) {
    const path = `/organization/employee-position-group`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, keyword, currentPage], () => requestGet(path, { sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDetailEmployeePositionGroup(ID, { expands, onSuccess = () => null }) {
    const path = `/organization/employee-position-group/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onSuccess })
}

export function useCreateEmployeePositionGroup({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ name, isHumanResourceManager, isOperationalStaff }) => requestPost(`/organization/employee-position-group`, { name, isHumanResourceManager, isOperationalStaff }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useUpdateEmployeePositionGroup({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, name, isHumanResourceManager, isOperationalStaff }) => requestPost(`/organization/employee-position-group/${ID}`, { name, isHumanResourceManager, isOperationalStaff }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useDeleteEmployeePositionGroup({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID }) => requestDelete(`/organization/employee-position-group/${ID}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}