/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, BanknotesIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useArrayState } from 'Utility/hook'
import { InputFormat, InputText } from 'UI/Component/Input'
import { v4 as uuid } from 'uuid';
import { humanizeCurrency } from 'Utility/humanize'
import { useCreateEmployeeSalary } from 'Data/Organization/employee-salary'


export default function ModalAddEmployeeSalary({ employee, open, onClose, onConfirm }) {
    const cancelButtonRef = useRef(null)
    const [baseAmount, setBaseAmount] = useState(0)
    const [items, addItem, removeItem, existItem, updateItem, resetItems] = useArrayState([], (itemA, itemB) => itemA.uuid === itemB.uuid)

    const createEmployeeSalary = useCreateEmployeeSalary({
        onSuccess: () => {
            setBaseAmount('')
            resetItems()
            onConfirm()
        },
    })

    const submit = (event) => {
        event.preventDefault()
        createEmployeeSalary.mutate({
            employeeID: employee.ID,
            items, baseAmount
        })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <BanknotesIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0  sm:text-left w-full mx-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Pembuatan Gaji Baru
                                        </Dialog.Title>

                                        <div className="px-4 sm:px-6 lg:px-8 mt-10">
                                            <div className="sm:flex sm:items-center">
                                                <div className="sm:flex-auto">
                                                    <h1 className="text-xl font-semibold text-gray-900">Nota Gaji</h1>
                                                    <p className="mt-2 text-sm text-gray-700">
                                                        Gaji ini akan berlaku sejak terbuatnya data ini
                                                    </p>
                                                </div>
                                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                    <button
                                                        onClick={() => addItem({ uuid: uuid(), name: '', amount: 0 })}
                                                        type="button"
                                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                                    >
                                                        Tambah komponen
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-10">
                                                <InputFormat
                                                    label="Gaji Pokok"
                                                    value={baseAmount}
                                                    onChange={value => setBaseAmount(value)}
                                                    numberProps={{
                                                        locale: 'id-ID',
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        precision: 0
                                                    }}
                                                    layoutProps={{}}
                                                />
                                            </div>
                                            <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                            >
                                                                Keterangan
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                            >
                                                                Nilai
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                                            >
                                                                Aksi
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {items.map((item, index) => (
                                                            <tr key={index} className="border-b border-gray-200">
                                                                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                                    <InputText
                                                                        label="Keterangan"
                                                                        value={item.name}
                                                                        onChange={(value) => updateItem(item, { name: value })}
                                                                    />
                                                                    <div className='block sm:hidden'>
                                                                        <InputFormat
                                                                            label="Nilai"
                                                                            value={item.amount}
                                                                            onChange={(value) => updateItem(item, { amount: value })}
                                                                            numberProps={{
                                                                                locale: 'id-ID',
                                                                                style: 'currency',
                                                                                currency: 'IDR',
                                                                                precision: 0
                                                                            }}
                                                                            layoutProps={{}}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                                                    <InputFormat
                                                                        label="Nilai"
                                                                        value={item.amount}
                                                                        onChange={(value) => updateItem(item, { amount: value })}
                                                                        numberProps={{
                                                                            locale: 'id-ID',
                                                                            style: 'currency',
                                                                            currency: 'IDR',
                                                                            precision: 0
                                                                        }}
                                                                        layoutProps={{}}
                                                                    />
                                                                </td>
                                                                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                                                    <button onClick={() => removeItem(item)}>
                                                                        <TrashIcon className='w-4 h-4' />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th
                                                                scope="row"
                                                                colSpan={3}
                                                                className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                                                            >
                                                                Pokok
                                                            </th>
                                                            <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                                                                Pokok
                                                            </th>
                                                            <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                                                {humanizeCurrency(baseAmount)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th
                                                                scope="row"
                                                                colSpan={3}
                                                                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                                                            >
                                                                Komponen
                                                            </th>
                                                            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                                                                Komponen
                                                            </th>
                                                            <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                                                {items.length ? humanizeCurrency(items.map(item => item.amount).reduce((sum, current) => sum + current)) : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th
                                                                scope="row"
                                                                colSpan={3}
                                                                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                            >
                                                                Total
                                                            </th>
                                                            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                                                Total
                                                            </th>
                                                            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                                                {humanizeCurrency(baseAmount + (items.length ? items.map(item => item.amount).reduce((sum, current) => sum + current) : 0))}
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <form onSubmit={submit}>
                                    <button
                                        type="submit"


                                        disabled={createEmployeeSalary.isLoading}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"

                                    >

                                        {createEmployeeSalary.isLoading ?
                                            <span className='flex flex-row gap-2 items-center'><ArrowPathIcon className='h-3 w-3 animate-spin' />  Saving..</span> :
                                            'Buat'
                                        }

                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        ref={cancelButtonRef}
                                        onClick={() => onClose()}
                                    >
                                        Batalkan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}