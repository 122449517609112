import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import React from 'react'
import classNames from "classnames"
import DashboardLayout from "UI/Layout/DashboardLayout"
import { useStatisticSummary } from 'Data/Information'
import { transformResponseToList } from 'Utility/formatter'
import { humanizeCurrency, humanizeDuration, humanizeNumber, humanizePercentage } from 'Utility/humanize'
import { useAuth } from 'App'
import AttendancePanel from './AttendancePanel'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { authenticationProvider } from 'Provider/Authentication'
import {
    Card,
    Metric,
    Text,
    AreaChart,
    BadgeDelta,
    Flex,
    DeltaType,
    ColGrid,
} from '@tremor/react';
import DashboardPanel from './DashboardPanel'



export default function Home() {
    const { user } = useAuth()

    const IconMap = { ...IconSolid }

    return (
        <DashboardLayout className="m-10">
            <div>
                <div>
                    <DashboardPanel />

                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                        {authenticationProvider.role() === 'Super-Administrator' ? null :
                            <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                                <dt>
                                    <div className="absolute bg-indigo-500 rounded-md p-3">
                                        <IconSolid.ClockIcon className='h-6 w-6 text-white' />
                                    </div>
                                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                        Total Jam Kerja
                                    </p>
                                </dt>
                                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                    <p className="text-2xl font-semibold text-gray-900">
                                        {!user.role.employee.roleEntity.todayAttendance.in || !user.role.employee.roleEntity.todayAttendance.out ? null :
                                            humanizeNumber(humanizeDuration(user.role.employee.roleEntity.todayAttendance.in.time, user.role.employee.roleEntity.todayAttendance.out.time).asHours(), 2)
                                        } Jam
                                    </p>
                                    <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                        <div className="text-sm">
                                            <Link to="/employee-attendance-log" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                {' '}
                                                Lihat semua<span className="sr-only">  presensi</span>
                                            </Link>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        }
                    </dl>


                    {authenticationProvider.role() === 'Super-Administrator' ? null :
                        <AttendancePanel />
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}
