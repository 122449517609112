import { Fragment, useState } from "react"
import { useMutationLogin } from "Data/Authentication/access"
import { useAuth } from "App"
import classNames from "classnames"
import { transformErrorNetwork, transformResponseError } from "Utility/formatter"
import toast from "react-hot-toast"
import { InputSecure, InputText } from "UI/Component/Input"
import Lottie from "lottie-react";
import { RadioGroup } from "@headlessui/react"
import { CheckCircleIcon } from "@heroicons/react/24/solid"

export default function Login() {
  const auth = useAuth()
  const [role, setRole] = useState('Employee')
  const [remember, setRemember] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const mutateLogin = useMutationLogin({
    role: role,
    onSuccess: ({ data: response }) => {
      if (response.code === 200) {
        setTimeout(() => {
          auth.signIn(response.data.account, response.data.token)
        }, 1200)
      }
    },
    onError: (error) => {
      toast.error(transformErrorNetwork(error))
    }
  })
  const error = transformResponseError(mutateLogin.error)

  const submit = (event) => {
    mutateLogin.mutate({ id: email, password: password })
    event.preventDefault()
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
          <img width={150} src={require('Asset/Logo/company-logo.png')} />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Masuk dengan menggunakan akun anda</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            atau{' '}
            <a href="mailto:mail@andryluthfi.com" className="font-medium text-indigo-600 hover:text-indigo-500">
              coba kontak administrator
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submit} className="space-y-6" action="#" method="POST">
              {mutateLogin.isLoading || mutateLogin.isSuccess ?
                <Fragment>
                  <Lottie animationData={require('Asset/Lottie/loading.json')} loop={true} />
                  <h1 className="text-center text-3xl">
                    {mutateLogin.isLoading ?
                      'Logging in ...' :
                      'Entering ...'
                    }
                  </h1>
                </Fragment> :
                <Fragment>
                  <InputText
                    label={'E-mail'}
                    value={email}
                    onChange={value => setEmail(value)}
                    placeholder="your@email.com"
                    errorMessage={error['body.username']}

                  />
                  <InputSecure
                    label={'Password'}
                    value={password}
                    onChange={value => setPassword(value)}
                    placeholder="your password/passphrases"
                    errorMessage={error['body.password']}
                  />
                  <RadioGroup value={role} onChange={setRole}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Pilih masuk sebagai</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      {[
                        { value: 'Employee', name: 'Karyawan', description: 'masuk sebagai karyawan, hanya dapat mengakses sebagian' },
                        { value: 'Super-Administrator', name: 'Administrator', description: 'masuk sebagai admin dengan kendali penuh' }
                      ].map((roleEntity) => (
                        <RadioGroup.Option
                          key={roleEntity.value}
                          value={roleEntity.value}
                          className={({ checked, active }) =>
                            classNames(
                              checked ? 'border-transparent' : 'border-gray-300',
                              active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                              'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <div className="flex-1 flex">
                                <div className="flex flex-col">
                                  <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                    {roleEntity.name}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                    {roleEntity.description}
                                  </RadioGroup.Description>

                                </div>
                              </div>
                              <CheckCircleIcon
                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                aria-hidden="true"
                              />
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'absolute -inset-px rounded-lg pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </Fragment>
              }
              <div className="form-control">
                <label className="cursor-pointer label">
                  <span className="label-text">Ingat saya (selama 1 bulan)</span>
                  <input type="checkbox" checked={remember} onChange={()=> setRemember(!remember)} className="checkbox checkbox-accent" />
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className={classNames(
                    "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                    mutateLogin.isLoading ? "bg-gray-200 hover:bg-gray-300 focus:ring-gray-100" : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                  )}
                >
                  {mutateLogin.isLoading ? 'Memproses' : 'Masuk'}
                </button>
              </div>
            </form>

            <div className="mt-6 hidden">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <button
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div>
                  <button
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </button>
                </div>

                <div>
                  <button
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
