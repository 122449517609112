import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, MagnifyingGlassIcon, MinusSmallIcon } from '@heroicons/react/24/outline'
import { transformResponseError, transformResponseToList } from 'Utility/formatter'
import { PlusIcon, PlusSmallIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useBrowseBank } from 'Data/Organization/bank'
import { useCreateEmployeeBankAccount } from 'Data/Organization/employee-bank-account'
import { InputText } from 'UI/Component/Input'

export default function ModalCreateEmployeeBankAccount({ employee, open, onClose, onConfirm }) {
    const cancelButtonRef = useRef(null)
    const [password, setPassword] = useState('')
    const [keyword, setKeyword] = useState('')
    const [selectedBank, selectBank] = useState(null)
    const [number, setNumber] = useState(null)

    const createEmployeeBankAccount = useCreateEmployeeBankAccount({ onSuccess: () => setPassword('') || setKeyword('') || selectBank('') || setNumber('') || onConfirm(), })
    const browseBank = useBrowseBank({ currentPage: 1, pageSize: 10, keyword })

    const banks = transformResponseToList(browseBank.data)
    const error = transformResponseError(createEmployeeBankAccount.error)

    const searchAccount = (event) => {
        event.preventDefault();
        browseBank.refetch()
    }

    const submit = (event) => {
        event.preventDefault()
        createEmployeeBankAccount.mutate({ bankID: selectedBank.ID, employeeID: employee.ID, number })
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <PlusSmallIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0  sm:text-left w-full mx-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Tambah Akun Bank
                                        </Dialog.Title>

                                        <div>
                                            <div className="text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 48 48"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                                    />
                                                </svg>
                                                <h2 className="mt-2 text-lg font-medium text-gray-900">Pilih Bank</h2>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Silakan pilih bank yang terdaftar dalam sistem.
                                                </p>
                                            </div>
                                            <form onSubmit={searchAccount} className="mt-6 flex">
                                                <label htmlFor="email" className="sr-only">
                                                    Keyword
                                                </label>
                                                <input
                                                    type="text"
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder="Cari Bank"
                                                    value={keyword}
                                                    onChange={({ target: { value } }) => setKeyword(value)}
                                                />
                                                <button
                                                    type="submit"
                                                    className="ml-4 flex-shrink-0 flex flex-row  items-center gap-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    <MagnifyingGlassIcon className='w-4 h-4 text-white' />
                                                    <span className='hidden md:block'>Pencarian</span>
                                                </button>
                                            </form>
                                        </div>
                                        <div className="mt-10">

                                            <ul role="list" className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                                {banks.map((bank) => (
                                                    <li key={bank.ID} className="py-4 flex items-center justify-between space-x-3">
                                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                            <div className="flex-shrink-0">
                                                                {bank.avatar ?
                                                                    <img className="h-10 w-10 rounded-full" src={bank.avatar} alt="" /> :
                                                                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                                                        <span className="font-medium leading-none text-white">
                                                                            {bank.name.substring(0, 3)}
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="min-w-0 flex-1">
                                                                <p className="text-sm font-medium text-gray-900 truncate">{bank.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <button
                                                                onClick={() => selectBank(selectedBank?.ID === bank.ID ? null : bank)}
                                                                type="button"
                                                                className={classNames(
                                                                    "inline-flex items-center py-2 px-3 border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2",
                                                                    (selectedBank?.ID === bank.ID) ? "bg-indigo-500 hover:bg-indigo-200 focus:ring-gray-500 text-white" : "text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-indigo-500"
                                                                )}
                                                            >
                                                                {selectedBank?.ID === bank.ID ?
                                                                    <MinusSmallIcon className="-ml-1 mr-0.5 h-5 w-5 " aria-hidden="true" /> :
                                                                    <PlusSmallIcon className="-ml-1 mr-0.5 h-5 w-5 " aria-hidden="true" />
                                                                }
                                                                <span className="text-sm font-medium ">
                                                                    {' '}
                                                                    Select <span className="sr-only">{bank.name}</span>{' '}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                                {banks.length === 0 && (
                                                    <div className='flex flex-col text-center my-5'>
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                vectorEffect="non-scaling-stroke"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                            />
                                                        </svg>
                                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No Bank</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Please create new Bank before using this feature.</p>
                                                        <div className="mt-6">
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            >
                                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                                New Account
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </ul>
                                        </div>
                                        <form onSubmit={submit}>
                                            <div className='mt-10 space-y-2'>
                                                <InputText
                                                    label="Nomor Rekening"
                                                    value={number}
                                                    onChange={value => setNumber(value)}
                                                    errorMessage={error['body.number']}
                                                />
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <form onSubmit={submit}>
                                    <button
                                        type="submit"


                                        disabled={createEmployeeBankAccount.isLoading}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"

                                    >

                                        {createEmployeeBankAccount.isLoading ?
                                            <span className='flex flex-row gap-2 items-center'><ArrowPathIcon className='h-3 w-3 animate-spin' />  Saving..</span> :
                                            'Assign'
                                        }

                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        ref={cancelButtonRef}
                                        onClick={() => onClose()}
                                    >
                                        Cancel
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}