import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseHarvestPlan({ sort = 'ID', order = 'desc', expands = [], pageSize = 20, currentPage = 1, farmID = null, date, state, employeeID, onSuccess }) {
    const path = `/transaction/harvest-plan`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, currentPage, date, farmID, employeeID, state], () => requestGet(path, { sort, order, pageSize, state, date, farmID, employeeID, currentPage: currentPage || 1, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useDetailHarvestPlan(ID, { expands = [], onSuccess = () => null }) {
    const path = `/transaction/harvest-plan/${ID}`
    return useQuery([path, ID], () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onSuccess })
}

export function useUpdateHarvestPlan({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, date, noteAmount, noteSize, syncYield }) => requestPost(`/transaction/harvest-plan/${ID}`, {  date, noteAmount, noteSize, syncYield}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useCreateHarvestPlan({ onSuccess = () => null, onError }) {
    const path = `/transaction/harvest-plan`
    const mutation = useMutation(({ farmID, noteAmount, noteSize, date }) => requestPost(path, { farmID, noteAmount, noteSize, date }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}



export function useBrowseHarvestPlanFile(ID, { onSuccess }) {
    const path = `/transaction/harvest-plan/${ID}/file`
    const data = useQuery([
        ...(path.split('/')),
    ], () => requestGet(path, {

    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useUploadHarvestPlaneFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        file,
    }) => requestPost(`/transaction/harvest-plan/${ID}/file`, {
        file
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useDeleteHarvestPlanFile(ID, { onSuccess = () => null, onError }) {
    const path = `/transaction/harvest-plan/${ID}/file`
    const mutation = useMutation(({ name }) => requestDelete(`${path}/${name}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}