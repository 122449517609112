import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import { useState } from "react"
import { IconSolid } from "UI/Component/Icon"
import { transformResponseToList } from "Utility/formatter"
import { useBrowseEmployeePositionGroup } from "../../Data/API"



export default function ComboboxEmployeePositionGroup({ label = null, value, onChange, nonSelectionText = '', errorMessage = null }) {
    const [keywordPositionGroup, setKeywordPositionGroup] = useState('')
    const browseEmployeePositionGroup = useBrowseEmployeePositionGroup({ expands: [], pageSize: -1 })
    const employeePositionGroups = transformResponseToList(browseEmployeePositionGroup.data)

    const filteredGroups =
        keywordPositionGroup === ''
            ? employeePositionGroups
            : employeePositionGroups.filter((employeePositionGroup) => {
                return employeePositionGroup.name.toLowerCase().includes(keywordPositionGroup.toLowerCase())
            })
    return (
        <div>
            <Combobox as="div" value={value} onChange={onChange}>
                {label && (
                    <Combobox.Label className="block text-sm font-medium text-gray-700 mb-1">
                        {label}
                    </Combobox.Label>
                )}
                <div className="relative">
                    <Combobox.Input
                        className={classNames(
                            "w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm",
                            errorMessage ? "border-red-300 text-red-900 focus-within:ring-rose-600 focus-within:border-rose-600" : "focus-within:ring-indigo-600 focus-within:border-indigo-600"
                        )}
                        onChange={(event) => setKeywordPositionGroup(event.target.value)}
                        displayValue={(groupID) => employeePositionGroups.find(group => group.ID == groupID)?.name ?? nonSelectionText}
                    />
                    {errorMessage && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <IconSolid.ExclamationCircleIcon className="h-5 w-5 text-rose-500" aria-hidden="true" />
                        </div>
                    )}
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <IconSolid.ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>

                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option
                            value={null}
                            className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                            }
                        >
                            {({ active, selected }) => (
                                <>
                                    <div className="flex items-center">
                                        <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                                            {nonSelectionText ? nonSelectionText : 'Tidak Memilih'}
                                        </span>
                                    </div>

                                    {selected && (
                                        <span
                                            className={classNames(
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                active ? 'text-white' : 'text-indigo-600'
                                            )}
                                        >
                                            <IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    )}
                                </>
                            )}
                        </Combobox.Option>
                        {filteredGroups.map((group) => (
                            <Combobox.Option
                                key={group.ID}
                                value={group.ID}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <div className="flex items-center">
                                            <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{group.name}</span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                                <IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            </Combobox>
            <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p>
        </div>
    )
}