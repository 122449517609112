import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import classNames from 'classnames';
import { Link, useSearchParams } from 'react-router-dom';
import { humanizeCurrency, humanizeDate, humanizeDateTime, humanizePercentage, humanizeRelativeFromDate } from 'Utility/humanize';
import { useDetailEmployeeContext } from './context';
import { Pagination } from 'UI/Component';
import { useBrowseHarvestPlan } from 'Data/Transaction/harvest-plan';
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter';
import TableLoader from 'UI/Component/TableLoader';

export default function DetailEmployeeBrowseHarvestPlan() {
    const { id } = useDetailEmployeeContext()
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')
    const show = searchParams.get('show')

    const browseHarvestPlan = useBrowseHarvestPlan({
        expands: ['farm'], pageSize: show, currentPage: page, employeeID: id
    })
    const harvestPlans = transformResponseToList(browseHarvestPlan.data)
    const pagination = transformResponseToPagination(browseHarvestPlan.data)

    return (
        <div className='m-10'>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Tambak
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Yield Ukuran
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Yield Kuantitas
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Tanggal
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {browseHarvestPlan.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :
                            harvestPlans.map((harvestPlan) =>
                                <tr key={harvestPlan.ID} className="bg-white">
                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <div className="flex">
                                            <Link to={`/harvest-plan/${harvestPlan.ID}`} className="group flex flex-col gap-1.5">
                                                <div className='inline-flex space-x-2 truncate text-sm'>
                                                    <IconOutline.ScaleIcon
                                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                    <p className="text-gray-500 truncate group-hover:text-gray-900">
                                                        {harvestPlan.farm.description} - {harvestPlan.farm.owner}
                                                    </p>
                                                </div>
                                                <time className='font-light' dateTime={harvestPlan.date}>
                                                    {humanizeDate(harvestPlan.date)}
                                                </time>
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                        <span className="text-gray-900 font-light text-sm font-mono">
                                            {humanizePercentage(harvestPlan.yieldSize)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                        <span className="text-gray-900 font-light text-sm font-mono">
                                            {humanizePercentage(harvestPlan.yieldAmount)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 ">
                                        {humanizeRelativeFromDate(harvestPlan.date)} <br />
                                        <time className='font-light text-xs' dateTime={harvestPlan.date}>
                                            {humanizeDate(harvestPlan.date)}
                                        </time>
                                    </td>
                                </tr>
                            )
                        }

                    </tbody>
                </table>
                <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show })} />
            </div>
        </div>
    )
}