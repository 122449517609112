import { useState, useEffect } from 'react'
import * as IconSolid from '@heroicons/react/24/solid';
import * as IconOutline from '@heroicons/react/24/outline';
import { useAuth } from 'App';
import classNames from 'classnames';
import { useCreateEmployeeAttendanceLog } from 'Data/Organization/employee-attendance-log';
import { Fragment } from 'react'
import { useGeolocated } from 'react-geolocated';
import { ActionDialog } from 'UI/Component/Dialog';
import { LocationInformation } from "UI/Component/Map";
import MapDisplay from "UI/Component/MapDisplay";
import { humanizeRelativeFromDate } from 'Utility/humanize';
import { InputTextArea } from 'UI/Component/Input';
import moment from 'moment';


export default function AttendanceLog({ type }) {
    const { user } = useAuth()
    const attendanceLog = user.role.employee.roleEntity.todayAttendance[type]

    return (
        <Fragment>
            {
                attendanceLog ?
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Jam</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {attendanceLog.time} ({humanizeRelativeFromDate(`${attendanceLog.date} ${attendanceLog.time}`)})
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Alamat Detail</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <LocationInformation
                                    object={attendanceLog}
                                    selector={object => object.location}
                                    isHistory={false}
                                />
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Catatan Tambahan</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {attendanceLog.note}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Peta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <MapDisplay
                                    height='300px'
                                    value={attendanceLog.location}
                                    defaultZoom={18.12}
                                />
                            </dd>
                        </div>
                    </dl> :
                    <AttendanceLogInput type={type} />
            }

        </Fragment>
    )
}

function AttendanceLogInput({ type }) {
    const [isReadyToSubmit, readyToSubmit] = useState(false)
    const { refreshAuth, user } = useAuth()
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
    });
    const createEmployeeAttendanceLog = useCreateEmployeeAttendanceLog({ onSuccess: () => refreshAuth() })
    return (
        !isGeolocationAvailable ?
            <GeolocationNotSupport /> :
            !isGeolocationEnabled ?
                <GeolocationNotEnabled /> :

                coords ? (
                    <dl className="sm:divide-y sm:divide-gray-200">

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500"></dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                    <button
                                        onClick={() => readyToSubmit(true)}
                                        type="button"

                                        disabled={createEmployeeAttendanceLog.isLoading}
                                        className={classNames(
                                            "relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
                                            createEmployeeAttendanceLog.isLoading ? 'opacity-40 animate-pulse' : ''
                                        )}
                                    >
                                        <IconOutline.ClockIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        Catat
                                    </button>

                                    <DialogSubmit
                                        open={isReadyToSubmit}
                                        coords={coords}
                                        onClose={() => readyToSubmit(false)}
                                        onConfirm={({ note }) => {
                                            createEmployeeAttendanceLog.mutateAsync({
                                                employeeID: user.role.employee.roleEntity.ID,
                                                type: type === 'in' ? 'In' : 'Out',
                                                latitude: coords.latitude,
                                                longitude: coords.longitude,
                                                note
                                            })
                                        }}
                                        isLoading={createEmployeeAttendanceLog.isLoading}
                                    />
                                </span>
                            </dd>
                        </div>

                    </dl>
                ) : (
                    <div>Getting the location data&hellip; </div>
                )
    )
}

const DialogSubmit = ({ open, onClose, onConfirm, isLoading, coords }) => {
    const [date, setDate] = useState(new Date());
    const [note, setNote] = useState('')

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {
        setDate(new Date());
    }

    const dateAsMoment = moment(date)

    return (
        <ActionDialog
            icon={IconSolid.ClockIcon}
            open={open} onClose={onClose}
            title="Presensi"
            onConfirm={() => onConfirm({ note })}
            confirm={(onActionConfirm) => (
                <button
                    type="submit"
                    onClick={() => onActionConfirm()}
                    disabled={isLoading}
                    className={classNames(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm",
                        isLoading ? 'bg-gray-500 text-white hover:bg-gray-500 animate-pulse' : 'bg-blue-600 text-white hover:bg-blue-700 '
                    )}

                >
                    {isLoading ? 'Menyimpan...' : 'Simpan'}
                </button>
            )}
        >
            <div className="min-h-[400px]">
                <div className='flex justify-center'>
                    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
                        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                            <span className="countdown font-mono text-5xl">
                                <span style={{ "--value": dateAsMoment.format('HH') }}></span>
                            </span>
                            jam
                        </div>
                        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                            <span className="countdown font-mono text-5xl">
                                <span style={{ "--value": dateAsMoment.format('mm') }}></span>
                            </span>
                            menit
                        </div>
                        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                            <span className="countdown font-mono text-5xl">
                                <span style={{ "--value": dateAsMoment.format('ss') }}></span>
                            </span>
                            detik
                        </div>
                    </div>
                </div>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-1 gap-2">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Alamat Detail</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <LocationInformation
                                object={{ location: coords }}
                                selector={object => object.location}
                                isHistory={false}
                            />
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Peta</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <MapDisplay
                                height='300px'
                                value={coords}
                                defaultZoom={18.12}
                            />
                        </dd>
                    </div>
                    <InputTextArea
                        label="Catatan Tambahan"
                        value={note}
                        onChange={setNote}
                        layoutProps={{
                            postFixContent: (
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <IconSolid.ScaleIcon className='text-gray-500 w-4 h-4' />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        </ActionDialog>
    )
}


const GeolocationNotSupport = () => (
    <div>Your browser does not support Geolocation</div>
)
const GeolocationNotEnabled = () => (
    <div>Geolocation is not enabled</div>
)