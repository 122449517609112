import * as IconSolid from '@heroicons/react/24/solid'
import { Fragment, useCallback, useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import moment from 'moment'
import { Link, useSearchParams } from 'react-router-dom'
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { Pagination } from 'UI/Component'
import TableLoader from 'UI/Component/TableLoader'
import { useBrowseEmployee } from 'Data/Organization/employee'
import EmployeeAccessSelection from './EmployeeAccessSelection'
import { humanizeCurrency, humanizeDate, humanizeRelativeFromDate } from 'Utility/humanize'
import spelled from '@develoka/angka-terbilang-js';

const now = moment()

export default function BrowseEmployee() {
    const [actionState, setActionState] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryKeyword, setQueryKeyword] = useState(searchParams.get('keyword'))
    const page = searchParams.get('page')
    const show = searchParams.get('show')
    const role = searchParams.get('role')
    const haveAccess = searchParams.get('haveAccess')
    const driveTruck = searchParams.get('driveTruck')
    const isInActive = searchParams.get('isInActive')
    const keyword = searchParams.get('keyword')

    const browseEmployee = useBrowseEmployee({ expands: ['access', 'position', 'salary'], currentPage: searchParams.get('page'), keyword, haveAccess, driveTruck, isInActive, pageSize: show })
    const pagination = transformResponseToPagination(browseEmployee.data)
    const employees = transformResponseToList(browseEmployee.data)

    const search = useCallback((event) => {
        setSearchParams({keyword: queryKeyword})
        event.preventDefault()
    })

    useEffect(() => {
        browseEmployee.refetch()
    }, [page, show, role, haveAccess, driveTruck, isInActive])

    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Employee
                    </h1>
                    <span className='text-indigo-600'>
                        Browse/Search
                    </span>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4">
                    <button
                        onClick={browseEmployee.refetch}
                        type="button"
                        className="order-1 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-0 sm:ml-0"
                    >
                        Muat Ulang <IconSolid.ArrowPathIcon className={classNames(
                            "ml-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 border-2 p-1 rounded-full",
                            browseEmployee.isFetching ? 'animate-spin' : ''
                        )} />
                    </button>
                    <Link
                        to="create"
                        className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-1 sm:ml-3"
                    >
                        Create new Employe
                    </Link>
                </div>
            </div>
            <div className="px-4 mt-6 sm:px-6 lg:px-8">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">Based on Role</h2>
                <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
                    <button onClick={() => setSearchParams({ haveAccess: '' })}>
                        <EmployeeAccessSelection color="bg-gray-500" haveAccess={null} title="All" />
                    </button>
                    <button onClick={() => setSearchParams({ haveAccess: true })}>
                        <EmployeeAccessSelection color="bg-indigo-500" haveAccess={true} title="Akses" />
                    </button>
                    <button onClick={() => setSearchParams({ haveAccess: false })}>
                        <EmployeeAccessSelection color="bg-red-500" haveAccess={false} title="Tanpa Akses" />
                    </button>
                    <button onClick={() => setSearchParams({ driveTruck: true })}>
                        <EmployeeAccessSelection color="bg-amber-500" driveTruck={true} title="Supir Truck" />
                    </button>
                    <button onClick={() => setSearchParams({ isInActive: false })}>
                        <EmployeeAccessSelection color="bg-green-500" isInActive={false} title="Aktif" />
                    </button>
                    <button onClick={() => setSearchParams({ isInActive: true })}>
                        <EmployeeAccessSelection color="bg-gray-500" isInActive={true} title="Inaktif" />
                    </button>
                </ul>
            </div>

            <div className='mt-5 mx-4'>
                <form onSubmit={search}>
                    <div className="flex-1 flex justify-center lg:justify-end ">
                        <div className="w-full px-2 lg:px-6 ">
                            <label htmlFor="search" className="sr-only">
                                Search Truck
                            </label>
                            <div className="relative text-indigo-200 focus-within:text-gray-400">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-indigo-800" aria-hidden="true" />
                                </div>
                                <input
                                    value={queryKeyword}
                                    className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-900 placeholder-indigo-800 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                                    placeholder="Ketik nama karyawan, posisi atau lainnya untuk mencari"
                                    type="search"
                                    onChange={({ target: { value } }) => setQueryKeyword(value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='mx-10 mb-10'>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Nama Lengkap
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Masa Bakti
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Identitas
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Posisi/Jabatan
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Gaji
                                </th>
                                <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Akses
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Aksi</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {browseEmployee.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :
                                employees.map((employee) =>
                                    <tr key={employee.ID} className={classNames(employee.inActiveDate ? 'opacity-40 hover:opacity-100' : '')}>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <Link to={`/employee/${employee.ID}`} className="flex-shrink-0 group block">
                                                <div className="flex items-center">
                                                    <div>
                                                        {employee.avatar ?
                                                            <img
                                                                className="inline-block h-9 w-9 rounded-full"
                                                                src={employee.avatar.URL}
                                                                alt=""
                                                            /> :
                                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                                                <span className="font-medium leading-none text-white">
                                                                    {employee.name.split(" ").map((part) => `${part[0]}`.toLocaleUpperCase()).join("")}
                                                                </span>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                            {employee.name}
                                                        </p>
                                                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>

                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            <div className='flex flex-row'>
                                                <div className='flex flex-row gap-1'>
                                                    <IconSolid.CalendarDaysIcon className='h-5 w-5' />
                                                    <div>
                                                        <div>{humanizeDate(employee.joinDate)}</div>
                                                        <div className='font-light text-xs'>{humanizeRelativeFromDate(employee.joinDate)}</div>
                                                    </div>
                                                </div>
                                                {employee.inActiveDate && (
                                                    <Fragment>
                                                        <div className='mr-3'>
                                                            <IconSolid.ArrowRightIcon className='h-4 w-4' />
                                                        </div>
                                                        <div className='flex flex-row gap-1'>
                                                            <IconSolid.CalendarDaysIcon className='h-5 w-5' />
                                                            <div>
                                                                <div>{humanizeDate(employee.inActiveDate)}</div>
                                                                <div className='font-light text-xs'>{humanizeRelativeFromDate(employee.inActiveDate)}</div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </td>
                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            <div className='flex gap-2'>
                                                {employee.employeeIdentityNumber && (
                                                    <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                        <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                            <span
                                                                className={classNames('bg-red-500', 'h-1.5 w-1.5 rounded-full')}
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                        <span className="ml-3.5 font-medium text-gray-900 flex items-center gap-1 justify-center">
                                                            NIP <span className='font-light text-xs'>{employee.employeeIdentityNumber}</span>
                                                        </span>
                                                    </div>
                                                )}
                                                {employee.nationalIdentityNumber && (
                                                    <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                        <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                            <span
                                                                className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                        <span className="ml-3.5 font-medium text-gray-900 flex items-center gap-1 justify-center">
                                                            NIK <span className='font-light text-xs'>{employee.nationalIdentityNumber}</span>
                                                        </span>
                                                    </div>
                                                )}

                                            </div>
                                        </td>

                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            {employee.position.name} <br />
                                            <span className='font-light'>{employee.position.group.name}</span>
                                        </td>

                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            <span title={employee.salary ? spelled(employee.salary.totalAmount) : '-'}>
                                                {employee.salary ?
                                                    humanizeCurrency(employee.salary.totalAmount) :
                                                    '-'
                                                }
                                            </span>
                                        </td>
                                        <td className="hidden lg:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                            {employee.access && (
                                                <button className=" relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">Access</span>
                                                </button>
                                            )}
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <Link to={`/employee/${employee.ID}`}>
                                                <span className='text-indigo-600 hover:text-indigo-900 hidden md:block'>
                                                    Ubah
                                                </span>
                                                <div className='bg-indigo-600 rounded-full p-2 flex justify-center items-center md:hidden'>
                                                    <IconSolid.PencilSquareIcon className='w-4 h-4 text-white ' />
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show, role, haveAccess, driveTruck, isInActive})} />
                </div>
            </div>
        </DashboardLayout>

    )
}
