import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseEmployeePosition({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, employeePositionGroupID, keyword, onSuccess }) {
    const path = `/organization/employee-position`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, keyword, currentPage, employeePositionGroupID], () => requestGet(path, { sort, order, keyword, pageSize,employeePositionGroupID, currentPage: currentPage || 1, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDetailEmployeePosition(ID, { expands, onSuccess = () => null }) {
    const path = `/organization/employee-position/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onSuccess })
}

export function useCreateEmployeePosition({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ name, employeePositionGroupID }) => requestPost(`/organization/employee-position`, { name, employeePositionGroupID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useUpdateEmployeePosition({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, name, employeePositionGroupID }) => requestPost(`/organization/employee-position/${ID}`, { name, employeePositionGroupID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useDeleteEmployeePosition({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID }) => requestDelete(`/organization/employee-position/${ID}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}