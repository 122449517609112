import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"
import { defaultObject } from "Utility/formatter"

export function useBrowseDelivery(inputParameters) {
    const parameters = defaultObject(inputParameters, { sort: 'ID', order: 'desc', pageSize: 20, currentPage: 1, keyword: '', expands: [], memberID: null, onSuccess: null })
    const path = `/transaction/delivery`
    const data = useQuery([
        ...(path.split('/')),
        parameters.sort, parameters.order, parameters.pageSize, parameters.currentPage, parameters.keyword,
        parameters.expands,
        parameters.memberID
    ],
        () => requestGet(path, {
            sort: parameters.sort, order: parameters.order, currentPage: parameters.currentPage, pageSize: parameters.pageSize, keyword: parameters.keyword,
            expand: parameters.expands.join(','),
            memberID: parameters.memberID,
        }), {
        onSuccess: (response) => parameters.onSuccess ? parameters.onSuccess(response) : null,
    })
    return data
}

export function useDetailDelivery(ID, inputParameters) {
    const parameters = defaultObject(inputParameters, { expands: [], onSuccess: () => null })
    const path = `/transaction/delivery/${ID}`
    return useQuery(path, () => requestGet(path, { expand: parameters.expands.join(',') }), { onSuccess: parameters.onSuccess })
}

export function useCreateDelivery({ onSuccess = () => null, onError }) {
    const path = `/transaction/delivery`
    const mutation = useMutation(({ factoryDemandID, harvestItemID }) => requestPost(path, { factoryDemandID, harvestItemID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useDeleteDelivery({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID }) => requestDelete(`/transaction/delivery/${ID}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}
