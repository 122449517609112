import { useState } from "react"
import { RadioGroup } from "@headlessui/react"
import { BriefcaseIcon, CheckCircleIcon, TruckIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { transformResponseToList } from "Utility/formatter"
import { Fragment } from "react"
import { useCallback } from "react"
import useDebounce from "Utility/hook"
import { useBrowseEmployeePosition } from "Data/Organization/employee-position"

export default function EmployeePositionChooser({ value, onChange }) {
    const [keyword, setKeyword] = useState('')
    const debounceKeyword = useDebounce(keyword, 500);
    const browseEmployeePosition = useBrowseEmployeePosition({ expands: [], currentPage: 1, pageSize: 3 * 3, keyword: debounceKeyword })
    const employeePositions = transformResponseToList(browseEmployeePosition.data)

    const search = useCallback((event) => {
        event.preventDefault()
        browseEmployeePosition.refetch()
    })

    return (
        <div className="m-6">
            <div>
                <div className="text-center">
                    <BriefcaseIcon
                        className="mx-auto h-12 w-12 text-gray-400"
                    />
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Select Position</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        You need to select existing position in our database. or you could search via keyword below.
                    </p>
                </div>
                <form onSubmit={search} className="mt-6 flex justify-center">
                    <div className="flex max-w-2xl w-full">
                        <label htmlFor="email" className="sr-only">
                            Keyword
                        </label>
                        <input
                            type="text"
                            value={keyword}
                            onChange={({ target: { value } }) => setKeyword(value)}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Enter a keyword to search truck position"
                        />
                        <button
                            type="submit"
                            className="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Search
                        </button>
                    </div>

                </form>
            </div>
            <div className="mt-10">
                <RadioGroup value={value} onChange={onChange}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Select model below</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {employeePositions.map((employeePosition) => (
                            <RadioGroup.Option
                                key={employeePosition.ID}
                                value={employeePosition}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? 'border-transparent' : 'border-gray-300',
                                        active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <Fragment>
                                        <div className="flex-1 flex">
                                            <div className="flex flex-col">
                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                    {employeePosition.name}
                                                </RadioGroup.Label>
                                                <RadioGroup.Label as="span" className="block text-sm font-light text-gray-900">
                                                    {employeePosition.group.name}
                                                </RadioGroup.Label>
                                            </div>
                                        </div>
                                        <CheckCircleIcon
                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                            aria-hidden="true"
                                        />
                                        <div
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'absolute -inset-px rounded-lg pointer-events-none'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Fragment>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
} 