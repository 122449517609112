import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import React, { useState, memo, Fragment } from 'react'
import classNames from "classnames"
import DashboardLayout from "UI/Layout/DashboardLayout"
import { transformResponseToList, transformResponseToSingle } from 'Utility/formatter'
import { humanizeNumber, humanizePercentage } from 'Utility/humanize'
import moment from 'moment'
import { useHeadlessCalender } from 'UI/Component/Calender'
import { useReportMillage } from 'Data/Information/report'
import { Dialog, Menu, RadioGroup, Transition } from '@headlessui/react'
import { authenticationProvider } from 'Provider/Authentication';
import SlideOver from 'UI/Component/SlideOver'
import { Link, useSearchParams } from 'react-router-dom'
import { generateArray } from 'Utility/generator'
import Lottie from "lottie-react";
import TruckChooser from 'UI/Component/Truck/TruckChooser'

const statuses = [
    { name: 'Semua', value: null, description: 'Menampilkan semua truk tanpa pengecualian' },
    {
        name: 'Aktif', value: 'active', description: 'Hanya menampilkan truk yang minimal dalam satu bulan memiliki millage lebih dari 0',
    }]

export default function MillageReport() {
    const [actionState, setActionState] = useState({ action: null })
    const { days, setCursorDate, cursorDate } = useHeadlessCalender({})
    const currentMonthDays = days.filter(({ isCurrentMonth }) => isCurrentMonth)
    const startDate = currentMonthDays.length > 0 ? currentMonthDays[0].date : moment().startOf('month').format('YYYY-MM-DD');
    const endDate = currentMonthDays.length > 0 ? currentMonthDays[currentMonthDays.length - 1].date : moment().endOf('month').format('YYYY-MM-DD')

    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get('status')

    const reportMillage = useReportMillage({ startDate, endDate, status })

    const items = transformResponseToList(reportMillage.data)
    const { statistic } = transformResponseToSingle(reportMillage.data) || {}

    return (
        <DashboardLayout className="m-10">
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Laporan Millage</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Laporan Detail Millage Driver per-bulan. Di halaman ini juga bisa simulasikan gaji driver berdasarkan KPI millage
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <div className="flex items-center rounded-md shadow-sm md:items-stretch mr-2">
                                    <button
                                        type="button"
                                        onClick={() => setCursorDate(cursorDate.clone().subtract(1, 'month'))}
                                        className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Previous month</span>
                                        <IconSolid.ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newCursor = moment()
                                            setCursorDate(newCursor)
                                        }}
                                        className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                                    >
                                        Bulan ini
                                    </button>
                                    <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newCursor = cursorDate.clone().add(1, 'month')
                                            setCursorDate(newCursor)
                                        }}
                                        className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Next month</span>
                                        <IconSolid.ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => reportMillage.refetch()}
                                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <IconSolid.ArrowPathIcon className={classNames("-ml-1 mr-2 h-5 w-5 ", reportMillage.isLoading ? 'animate-spin text-info' : 'text-gray-400')} aria-hidden="true" />
                                    Refresh
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setActionState({ action: 'filter' })}
                                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <IconSolid.AdjustmentsHorizontalIcon className={classNames("-ml-1 mr-2 h-5 w-5 ", reportMillage.isLoading ? 'animate-pulse text-info' : 'text-gray-400')} aria-hidden="true" />
                                    Filter
                                    <div className='ml-2 flex gap-1.5'>
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                            <IconOutline.CalendarIcon className='-ml-0.5 mr-1.5 h-4 w-4 text-indigo-400' />
                                            {cursorDate.format('MMM YYYY')}
                                        </span>
                                    </div>
                                    <div className='ml-2 flex gap-1.5'>
                                        <span className={classNames("inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium", status === 'active' ? 'bg-teal-100 text-teal-800' : 'bg-gray-100 text-gray-800')}>
                                            <IconOutline.TruckIcon className={classNames('-ml-0.5 mr-1.5 h-4 w-4', status === 'active' ? 'text-teal-400' : 'text-gray-400')} />
                                            {status === 'active' ? 'Aktif' : 'Semua'}
                                        </span>
                                    </div>
                                </button>
                                <a
                                    href={`${process.env.REACT_APP_PROXY_BASE_URL_API ? process.env.REACT_APP_PROXY_BASE_URL_API : process.env.REACT_APP_BASE_URL_API}/information/report/millage/export?startDate=${startDate}&endDate=${endDate}&token=${authenticationProvider.token()}`}
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <IconOutline.ArrowDownTrayIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                        <thead className="bg-gray-50">
                                            <tr className='divide-x divide-gray-200'>
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                                >
                                                    Driver
                                                </th>
                                                {currentMonthDays.map(({ date, isCurrentMonth, isToday }) =>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                                    >
                                                        <div className='flex flex-col items-center'>
                                                            <div>{moment(date).format('DD')}</div>
                                                            <div className='text-gray-500 font-light'>{moment(date).format('MMM')}</div>
                                                        </div>
                                                    </th>
                                                )}
                                                <th
                                                    scope="col"
                                                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                                >
                                                    Kesimpulan
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {reportMillage.isLoading ?
                                                <RowLoader currentMonthDays={currentMonthDays} totalRow={50} /> :
                                                items.length === 0 ?
                                                    <tr className="divide-x divide-gray-200">
                                                        <td colSpan={2 + currentMonthDays.length} className="whitespace-nowrap p-1 text-sm text-gray-500 ">
                                                            <div className='flex flex-col mb-10 items-center justify-center'>
                                                                <div className='w-56'>
                                                                    <Lottie animationData={require('Asset/Lottie/empty.json')} loop={true} />
                                                                </div>
                                                                <h1 class="text-5xl font-bold">Tidak Ada Data</h1>
                                                                <p class="py-6">Tidak ada data pada filter ini. Coba ganti bulan atau hapus beberapa filter yang diterapkan.</p>
                                                            </div>
                                                        </td>
                                                    </tr> :
                                                    items.map(({ truck, summary, ...dates }) => (
                                                        <tr key={truck.ID} className="divide-x divide-gray-200">
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                                                <Link to={`/truck/${truck.ID}`} className="link link-primary">
                                                                    {truck.ID}
                                                                </Link>
                                                            </td>
                                                            {currentMonthDays.map(({ date }) => (
                                                                <td className="whitespace-nowrap p-1 text-sm text-gray-500 ">
                                                                    <div className='flex items-center'>
                                                                        <div className='text-xs'>
                                                                            {dates[date] === null ? '-' :
                                                                                <span className={classNames('font-mono', dates[date].millage <= 0 ? 'text-gray-300' : 'text-gray-800')}>
                                                                                    {humanizeNumber(dates[date].millage / 1000, 2)}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                            ))}
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                                                <div className='flex flex-col gap-2'>
                                                                    <progress className="progress " value={summary.millageSum} max={statistic?.millagePeerAverage}></progress>

                                                                    <div className='flex gap-1.5 items-center'>
                                                                        {summary.millageSum == statistic?.millagePeerAverage ? null :
                                                                            summary.millageSum > statistic?.millagePeerAverage ?
                                                                                <IconSolid.ArrowTrendingUpIcon className='w-4 h-4 text-success' /> :
                                                                                <IconSolid.ArrowTrendingDownIcon className='w-4 h-4 text-error' />
                                                                        }
                                                                        <span className='text-sm text-gray-900 font-mono'>
                                                                            {humanizeNumber(summary.millageSum / 1000, 2)}
                                                                            <span className='text-gray-400'>/{humanizeNumber(statistic?.millagePeerAverage / 1000, 2)}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SlideOver open={actionState.action == 'filter'} onClose={() => setActionState({ action: null })}>
                {({ onClose }) => (
                    <Fragment>
                        <div className="px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between">
                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                    Pengaturan Filter
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                        onClick={() => onClose()}
                                    >
                                        <span className="sr-only">
                                            Tutup
                                        </span>
                                        <IconSolid.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>

                            <div className="relative mt-4">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                            </div>

                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                            <div className="px-4 py-5 sm:px-0 sm:py-0">
                                <div className='mx-6 my-4 md:mx-10'>
                                    <label className="text-base font-medium text-gray-900">Status Driver</label>
                                    <p className="text-sm leading-5 text-gray-500">Pilih truk yang ingin ditampilkan</p>
                                    <div className='absolute right-0'>

                                    </div>
                                    <fieldset className="mt-4">
                                        <legend className="sr-only">Status:</legend>
                                        <div className="space-y-4">
                                            {statuses.map(({ name, value, description }, index) => (
                                                <div key={index} className="relative flex items-start py-4">
                                                    <div className="min-w-0 flex-1 text-sm">
                                                        <label className="font-medium text-gray-700">
                                                            {name}
                                                        </label>
                                                        <p className="text-gray-500">
                                                            {description}
                                                        </p>
                                                    </div>
                                                    <div className="ml-3 flex items-center h-5">
                                                        <input
                                                            name="status"
                                                            type="radio"
                                                            defaultChecked={value === status}
                                                            onChange={() => setSearchParams(value === null ? {} : { status: value })}
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </SlideOver>
        </DashboardLayout>
    )
}



const RowLoader = function ({ currentMonthDays, totalRow }) {
    return generateArray(totalRow).map((_, index) =>
        <tr key={index} className="divide-x divide-gray-200">
            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                <IconSolid.TruckIcon className='w-4 h-4 text-gray-400 animate-pulse' />
            </td>
            {currentMonthDays.map(({ date }) => (
                <td className="whitespace-nowrap p-1 text-sm text-gray-500 ">
                    <div className='flex items-center'>
                        <div className='text-xs'>
                            <progress className="progress w-6"></progress>
                        </div>
                    </div>

                </td>
            ))}
            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                <div className='flex flex-col gap-2'>
                    <progress className="progress w-12"></progress>
                </div>
            </td>
        </tr>
    )
}