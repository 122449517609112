import * as IconSolid from '@heroicons/react/24/solid'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { humanizeCurrency, humanizeDate, humanizeDateTime, humanizeRelativeFromDate } from 'Utility/humanize';
import { useDetailCourierContext } from './context';



export default function DetailCourierBasicInformation() {
    const { courier, setActionState, courierSalaries, selectActionObject } = useDetailCourierContext()

    return (
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                {/* Description list*/}

                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Person
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Person basic information.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Full Name
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {courier.name}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>


            </div>

            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 w-full space-y-4">
                {courier.access && (
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Access
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Courier access information.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Assined at</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <div className=''>{humanizeDateTime(courier.access.created)}</div>
                                        <div className='font-light text-xs'>{courier.access.created}</div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                            <button onClick={() => setActionState('invoke-courier')} className="w-0 flex-1 flex">
                                <a
                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                >
                                    <span className="ml-3">Invoke Role</span>
                                </a>
                            </button>
                        </div>
                    </div>
                )}
                {!courier.access && (
                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Access
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Courier access information.</p>
                        </div>

                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                            <button onClick={() => setActionState('assign-account')} className="w-0 flex-1 flex">
                                <a
                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                >
                                    <span className="ml-3">Assign Access</span>
                                </a>
                            </button>
                        </div>
                    </div>
                )}


                
                
            </section>

        </div>
    )
}