import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { humanizeDateTime, humanizeNumber } from "Utility/humanize";
import { IconOutline } from "./Icon";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.setRegion("id");

export function TextReverseGeocode({ latitude, longitude, className, loader = null }) {
    const [landmark, setLandmark] = useState('')
    const [isLoading, loading] = useState(true)

    useEffect(() => {
        if (latitude && longitude) {
            Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
                (response) => {
                    loading(false)
                    if (response.results.length > 0) {
                        setLandmark(response.results[0].formatted_address)
                    }
                },
                (error) => console.error(error)
            )
        }
    }, [latitude, longitude])

    return (
        <span className={className}>
            {isLoading ? loader : null}
            {landmark}
        </span>
    )
}

export function LocationInformation({ object, selector = (object) => object.lastLocation, isHistory = true, defaultShown = false }) {
    const [shown, show] = useState(defaultShown)
    const lastLocation = selector(object)

    const markerClassName = (lastLocation) => {
        if (!isHistory) {
            return "text-lime-600"
        }
        if (!lastLocation) {
            return "text-gray-600"
        }
        const now = moment()
        const current = moment(lastLocation.datetime, 'YYYY-MM-DD HH:mm:ss')
        const duration = moment.duration(now.diff(current))

        if (duration.asMinutes() <= 30) {
            return "text-lime-600"
        }
        if (duration.asMinutes() <= 60) {
            return "text-amber-600"
        }
        return "text-rose-600"
    }

    return (
        <div className='flex flex-row gap-2 items-start'>
            <div>
                <IconOutline.MapPinIcon className={classNames("mt-1 w-4 h-4 ", markerClassName(lastLocation))} />
            </div>
            <div>
                <span className='text-xs font-light font-mono'>( {humanizeNumber(lastLocation.latitude, 3, 3)}, {humanizeNumber(lastLocation.longitude, 3, 3)} )</span><br />
                {isHistory && (
                    <>
                        <span className='text-sm font-light'>{humanizeDateTime(lastLocation.datetime)}</span><br />
                    </>
                )}
                {lastLocation.landmark && lastLocation.landmark !== '' && lastLocation.landmark !== '-' ? lastLocation.landmark :
                    shown ?
                        <span className='text-md'>
                            <TextReverseGeocode
                                loader={
                                    <button className="whitespace-nowrap flex gap-1.5 items-center font-medium text-blue-700 hover:text-blue-600 opacity-40 animate-pulse">
                                        Alamat <IconOutline.MapIcon className='w-4 h-4' />
                                    </button>
                                }
                                latitude={lastLocation.latitude}
                                longitude={lastLocation.longitude}
                                className="font-sans text-gray-500 text-xs"
                            />
                        </span> :
                        <button type='button' onClick={() => show(true)} className="whitespace-nowrap flex gap-1.5 items-center font-medium text-blue-700 hover:text-blue-600">
                            Alamat <IconOutline.MapIcon className='w-4 h-4' />
                        </button>
                }
            </div>
        </div>
    )
}