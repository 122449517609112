/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, MagnifyingGlassIcon, MinusSmallIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import { useAssignAccountEmployee, useBrowseAccount, useCreateAccount } from 'Data/Authentication/account'
import { transformResponseError, transformResponseToList } from 'Utility/formatter'
import { PlusIcon, PlusSmallIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { InputSecure, InputText } from 'UI/Component/Input'


export default function CreateNewAccount({ open, onClose, onConfirm }) {
    const cancelButtonRef = useRef(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState()
    const createAccount = useCreateAccount({ onSuccess: () => onConfirm() })
    const error = transformResponseError(createAccount.error)

    const submit = (event) => {
        createAccount.mutate({ email, password })
        event.preventDefault()
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <form onSubmit={submit}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <UserPlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0  sm:text-left w-full mx-4">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                Buat Akses Baru
                                            </Dialog.Title>

                                            <div className='mt-2 space-y-2'>
                                                <div>
                                                    <InputText
                                                        label={'E-mail'}
                                                        value={email}
                                                        onChange={value => setEmail(value)}
                                                        placeholder="your@email.com"
                                                        errorMessage={error['body.email']}
                                                    />

                                                </div>
                                                <div>
                                                    <InputSecure
                                                        label={'Password'}
                                                        value={password}
                                                        onChange={value => setPassword(value)}
                                                        placeholder="your password/passphrases"
                                                        errorMessage={error['body.password']}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="submit"


                                        disabled={createAccount.isLoading}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"

                                    >

                                        {createAccount.isLoading ?
                                            <span className='flex flex-row gap-2 items-center'><ArrowPathIcon className='h-3 w-3 animate-spin' />  Saving..</span> :
                                            'Register'
                                        }

                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        ref={cancelButtonRef}
                                        onClick={() => onClose()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </form>
            </Dialog>
        </Transition.Root>
    )
}