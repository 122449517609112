import { useState } from "react";
import Sidebar from "./Sidebar";
import * as IconOutline from '@heroicons/react/24/outline'


export { Sidebar }
export default function DashboardLayout({ children, className = null }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={(state) => setSidebarOpen(state)} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <IconOutline.Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <main className="flex-1">
                    <div className="py-6">
                        <div className={className}>
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}