import { useMutation, useQuery } from "react-query"
import { requestPost, requestGet, requestDelete } from "Provider/RestClient"
import queryString from 'query-string'

export function useBrowseAccount({ sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword, role = null, onSuccess }) {
    const path = `/authentication/account`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, currentPage, keyword, role], () => requestGet(path, { keyword, sort, order, pageSize, role, currentPage: currentPage || 1, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDetailAccount(ID, { expands, onFetched = () => null }) {
    const path = `/authentication/account/${ID}`
    return useQuery(path, () => requestGet(path, { expand: expands.join(',') }), { onSuccess: onFetched })
}


export function useCreateAccount({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ email, password }) => requestPost(`/authentication/account`, { email, password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useUpdateAccountPassword({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, password, passwordConfirmation }) => requestPost(`/authentication/account/${ID}/password`, { password, passwordConfirmation }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useAssignAccountSuperAdministrator({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, password }) => requestPost(`/authentication/account/${ID}/super-administrator`, { password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useInvokeAccountSuperAdministrator({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, password }) => requestDelete(`/authentication/account/${ID}/super-administrator`, { password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useAssignAccountEmployee({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, employee, password }) => requestPost(`/authentication/account/${ID}/employee`, { password, employee }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}

export function useInvokeAccountEmployee({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ ID, password }) => requestDelete(`/authentication/account/${ID}/employee`, { password }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}
