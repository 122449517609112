import { ArrowRightIcon, ComputerDesktopIcon, DocumentIcon, HomeIcon } from '@heroicons/react/24/solid';
import {
    Card,
    Metric,
    Text,
    AreaChart,
    BadgeDelta,
    Flex,
    DeltaType,
    ColGrid,
    Title,
    Divider,
    List,
    Footer,
    ListItem,
    Icon,
    Block,
    Bold,
    ButtonInline,
} from '@tremor/react';

const data = [
    {
        Month: 'Jan 21',
        'Instant Delivery': 2890,
        'Sameday Delivery': 2400,
    },
    {
        Month: 'Feb 21',
        'Instant Delivery': 1890,
        'Sameday Delivery': 1398,
    },
    {
        Month: 'Mar 21',
        'Instant Delivery': 2190,
        'Sameday Delivery': 1900,
    },
    {
        Month: 'Apr 21',
        'Instant Delivery': 3470,
        'Sameday Delivery': 3908,
    },
    {
        Month: 'May 21',
        'Instant Delivery': 2170,
        'Sameday Delivery': 4800,
    },
    {
        Month: 'Jun 21',
        'Instant Delivery': 3170,
        'Sameday Delivery': 3800,
    },
    {
        Month: 'Jul 21',
        'Instant Delivery': 3490,
        'Sameday Delivery': 4300,
    },
];

const categories = [
    {
        title: 'Instant Delivery',
        metric: 'Rp 120.699K',
        metricPrev: 'Rp. 90.456K',
        delta: '34.3%',
        deltaType: 'moderateIncrease',
    },
    {
        title: 'Sameday Delivery',
        metric: '$ 120.348K',
        metricPrev: '$ 100.456K',
        delta: '18.1%',
        deltaType: 'moderateIncrease',
    }
];


const march = [
    {
        name: 'Elektronik',
        icon: ComputerDesktopIcon,
        color: 'sky',
        numTransactions: 24,
        amount: 'Rp. 42.300.000',
    },
    {
        name: 'F&B',
        icon: HomeIcon,
        color: 'orange',
        numTransactions: 4,
        amount: 'Rp. 12.300.000',
    },
    {
        name: 'Other',
        icon: DocumentIcon,
        color: 'pink',
        numTransactions: 11,
        amount: 'Rp. 2.600.000',
    },
    {
        name: 'Document',
        icon: DocumentIcon,
        color: 'emerald',
        numTransactions: 2,
        amount: 'Rp. 1.300.000',
    },
];

const april = [
    {
        name: 'F&B',
        icon: HomeIcon,
        color: 'orange',
        numTransactions: 4,
        amount: 'Rp. 200.300.000',
    },
    {
        name: 'Elektronik',
        icon: ComputerDesktopIcon,
        color: 'sky',
        numTransactions: 24,
        amount: 'Rp. 42.670.000',
    },
    {
        name: 'Document',
        icon: DocumentIcon,
        color: 'emerald',
        numTransactions: 2,
        amount: 'Rp. 1.100.000',
    },
    {
        name: 'Other',
        icon: DocumentIcon,
        color: 'pink',
        numTransactions: 11,
        amount: 'Rp. 300.000',
    },
];

const may = [
    {
        name: 'F&B',
        icon: HomeIcon,
        color: 'orange',
        numTransactions: 4,
        amount: 'Rp. 122.142.000',
    },
    {
        name: 'Elektronik',
        icon: ComputerDesktopIcon,
        color: 'sky',
        numTransactions: 24,
        amount: 'Rp. 34.305.000',
    },
    {
        name: 'Other',
        icon: DocumentIcon,
        color: 'pink',
        numTransactions: 11,
        amount: 'Rp. 32.030.000',
    },
    {
        name: 'Document',
        icon: DocumentIcon,
        color: 'emerald',
        numTransactions: 2,
        amount: 'Rp. 1.300.000',
    },
];

const months = [
    {
        name: 'December 2022',
        data: march,
    },
    {
        name: 'January 2023',
        data: april,
    },
    {
        name: 'February 2023',
        data: may,
    },
];


const valueFormatter = (number) => `$ ${Intl.NumberFormat('us').format(number).toString()}`;

export default function DashboardPanel() {
    return (
        <div className='my-10 flex flex-col gap-10'>
            <div>
                <Title>Revenue</Title>
                <Text>Last month revenue statistic based on Type of deliveries</Text>
                <Divider />

                <ColGrid numColsSm={2} numColsLg={4} gapX="gap-x-6" gapY="gap-y-6">
                    {categories.map((item) => (
                        <Card key={item.title}>
                            <Flex alignItems="items-start">
                                <Text>
                                    {item.title}
                                </Text>
                                <BadgeDelta deltaType={item.deltaType} text={item.delta} />
                            </Flex>
                            <Flex
                                justifyContent="justify-start"
                                alignItems="items-baseline"
                                spaceX="space-x-3"
                                truncate={true}
                            >
                                <Metric>
                                    {item.metric}
                                </Metric>
                                <Text>
                                    from
                                    {' '}
                                    {item.metricPrev}
                                </Text>
                            </Flex>
                            <AreaChart
                                marginTop="mt-4"
                                data={data}
                                dataKey="Month"
                                valueFormatter={valueFormatter}
                                categories={[item.title]}
                                colors={['blue']}
                                showXAxis={true}
                                showGridLines={false}
                                startEndOnly={true}
                                showYAxis={false}
                                showLegend={false}
                                height="h-40"
                            />
                        </Card>
                    ))}
                </ColGrid>
            </div>

            <div>
                <Title>Revenue</Title>
                <Text>Last month revenue statistic based on Type of deliveries</Text>
                <Divider />

                <ColGrid numColsSm={2} numColsLg={3} gapX="gap-x-6" gapY="gap-y-6">
                    {months.map((item) => (
                        <Card key={item.name}>
                            <Title>Transaction Volume</Title>
                            <Text>{item.name}</Text>
                            <List marginTop="mt-4">
                                {item.data.map((transaction) => (
                                    <ListItem key={transaction.name}>
                                        <Flex justifyContent="justify-start" spaceX="space-x-4" truncate={true}>
                                            <Icon
                                                variant="light"
                                                icon={transaction.icon}
                                                size="md"
                                                color={transaction.color}
                                            />
                                            <Block truncate={true}>
                                                <Text truncate={true}><Bold>{transaction.name}</Bold></Text>
                                                <Text truncate={true}>{`${transaction.numTransactions} transactions`}</Text>
                                            </Block>
                                        </Flex>
                                        <Text>{transaction.amount}</Text>
                                    </ListItem>
                                ))}
                            </List>
                            <Footer>
                                <ButtonInline
                                    size="sm"
                                    text="View details"
                                    icon={ArrowRightIcon}
                                    iconPosition="right"
                                />
                            </Footer>
                        </Card>
                    ))}
                </ColGrid>
            </div>
        </div>
    );
}