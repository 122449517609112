import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { DetailCourierProvider, useDetailCourierContext } from './context'
import { Link } from 'react-router-dom'
import { humanizeCurrency, humanizeDate, humanizeNumber, humanizeRelativeFromDate } from 'Utility/humanize'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { TabViewRoutes } from 'UI/Component/TabView'

import classNames from 'classnames'

import DashboardLayout from 'UI/Layout/DashboardLayout'
import HeaderCard from './HeaderCard'
import DetailCourierBasicInformation from './DetailCourierBasicInformation'
import DetailCourierBrowseFile from './DetailCourierBrowseFile'

export default function DetailCourier() {
    return (
        <DetailCourierProvider>
            <DetailCourierContexted />
        </DetailCourierProvider>
    )
}

function DetailCourierContexted() {
    const { id, detailCourier, courier, updateCourier, setActionState, copyBankAccountNumber } = useDetailCourierContext()

    if (detailCourier.isLoading) {
        return null
    }

    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <main className="py-10 w-full">
                    {/* Page header */}
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div className="flex-shrink-0">
                                <div className="relative">
                                    {courier.avatar ?
                                        <img
                                            className="h-16 w-16 rounded-full"
                                            src={courier.avatar.fullURL}
                                            alt=""
                                        /> :
                                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                            <span className={classNames("font-medium leading-none text-white ")}>
                                                {courier.name.split(" ").map((part) => `${part[0]}`.toLocaleUpperCase()).join("")}
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className=''>
                                <h1 className={classNames("text-lg md:text-2xl font-bold text-gray-900", courier.inActiveDate ? 'line-through' : '')}>{courier.name}</h1>
                                {courier.access && (
                                    <button className="mt-1 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                        <span className="absolute flex-shrink-0 flex items-center justify-center">
                                            <span
                                                className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                                aria-hidden="true"
                                            />
                                        </span>
                                        <span className="ml-3.5 font-medium text-gray-900">Access</span>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                            <Menu as="div" className="relative text-left inline-block lg:hidden">
                                <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                        Menu
                                        <IconSolid.ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-40">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link

                                                        to={`/courier/${id}/update`}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'group flex items-center px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <IconSolid.PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                        Ubah
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => updateCourier.mutate({ isInActive: courier.inActiveDate ? false : true })}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'group flex items-center px-4 py-2 text-sm gap-4'
                                                        )}
                                                    >
                                                        {courier.inActiveDate ? <IconSolid.RocketLaunchIcon className='w-4 h-4 text-gray-400' /> : <IconSolid.TrashIcon className='w-4 h-4 text-gray-400' />}
                                                        {courier.inActiveDate ? 'Aktifkan' : 'Nonaktifkan'}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <button
                                type="button"
                                onClick={() => updateCourier.mutate({ isInActive: courier.inActiveDate ? false : true })}
                                className={classNames(
                                    "hidden lg:inline-flex  gap-1 items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100",
                                    courier.inActiveDate ? "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500" : "bg-rose-600 hover:bg-rose-700 focus:ring-rose-500"
                                )}
                            >
                                {courier.inActiveDate ? <IconSolid.RocketLaunchIcon className='w-4 h-4 text-white' /> : <IconSolid.TrashIcon className='w-4 h-4 text-white' />}
                                {courier.inActiveDate ? 'Aktifkan' : 'Nonaktifkan'}
                            </button>
                            <Link
                                to={`/courier/${id}/update`}
                                className="hidden lg:inline-flex  items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                Ubah
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Disclosure>
                            {({ open }) => (
                                <Fragment>
                                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">


                                    </dl>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Disclosure.Panel as="dl" className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                            <HeaderCard
                                                label={`Trip Count (Today)`}
                                                value={humanizeNumber(courier.statistic.trip.count.today, 0, 0)}
                                                icon={IconSolid.BriefcaseIcon}
                                            />
                                            <HeaderCard
                                                label={`Trip Distanace (Today)`}
                                                value={<span>{humanizeNumber(courier.statistic.trip.distance.today)} <span className='text-gray-400'>Km</span></span>}
                                                icon={IconSolid.CalendarDaysIcon}
                                            />
                                            <HeaderCard
                                                label={`Income (Today)`}
                                                value={<span>{humanizeCurrency(courier.statistic.money.income.today)}</span>}
                                                icon={IconSolid.BuildingLibraryIcon}
                                            />

                                        </Disclosure.Panel>
                                    </Transition>
                                    <Disclosure.Button as='div' className="relative mt-5">

                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center">
                                            <button
                                                type="button"
                                                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <IconSolid.ChevronDoubleDownIcon className={classNames("-ml-1.5 mr-1 h-5 w-5 text-gray-400", open ? 'rotate-180 transform' : '')} />
                                                <span>Lihat lebih banyak</span>
                                            </button>
                                        </div>


                                    </Disclosure.Button>
                                </Fragment>
                            )}
                        </Disclosure>
                    </div>

                    <div className={classNames(courier.inActiveDate ? 'opacity-40 hover:opacity-100' : '')}>
                        <TabViewRoutes
                            basePath={`/courier/${id}`}
                            routes={[
                                { to: ``, label: 'Informasi Dasar', icon: { solid: IconSolid.IdentificationIcon, outline: IconOutline.IdentificationIcon }, element: DetailCourierBasicInformation },
                                { to: `/file`, label: 'Berkas', icon: { solid: IconSolid.FolderIcon, outline: IconOutline.FolderIcon }, element: DetailCourierBrowseFile },

                            ]}
                        />
                    </div>
                </main>
            </div>
        </DashboardLayout>
    )
}