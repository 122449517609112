import { useState, useCallback, Fragment } from 'react'
import { ActionDialog } from '../Dialog'
import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import { useBrowseEmployee } from 'Data/Organization/employee'
import useDebounce from 'Utility/hook'
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { Pagination } from '..'

export default function EmployeeChooser({ value, onChange, disabledSelection = null }) {
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const debounceKeyword = useDebounce(keyword, 500);
    const browseEmployee = useBrowseEmployee({ expands: ['access'], currentPage, pageSize, keyword: debounceKeyword })
    const employees = transformResponseToList(browseEmployee.data)
    const pagination = transformResponseToPagination(browseEmployee.data)

    const search = useCallback((event) => {
        event.preventDefault()
        browseEmployee.refetch()
    })

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-10">

            <div>
                <div className="text-center">
                    <IconSolid.UserCircleIcon
                        className="mx-auto h-12 w-12 text-gray-400"
                    />
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Cari Karyawan</h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Cari Karyawan dalam database
                    </p>
                </div>
                <form onSubmit={search} className="mt-6 flex justify-center">
                    <div className="flex max-w-2xl w-full">
                        <label htmlFor="email" className="sr-only">
                            Kata Kunci
                        </label>
                        <input
                            type="text"
                            value={keyword}
                            onChange={({ target: { value } }) => setKeyword(value)}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Enter a keyword to search truck model"
                        />
                        <button
                            type="submit"
                            className="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Search
                        </button>
                    </div>

                </form>
            </div>
            <div className="mt-10">
                <RadioGroup value={value} onChange={onChange}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Select model below</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {employees.map((employee) => (
                            <RadioGroup.Option
                                key={employee.ID}
                                value={employee}
                                disabled={disabledSelection === null ? false : disabledSelection(employee)}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? 'border-transparent' : 'border-gray-300',
                                        active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                                        disabledSelection === null ? false : disabledSelection(employee) ? 'opacity-30' : ''
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <Fragment>
                                        <div className="flex-1 flex">
                                            <div className="flex flex-col">
                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                    {employee.name}
                                                    <div className="absolute right-3 top-3">
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral-focus text-neutral-content rounded-full w-8">
                                                                <span className="text-xs">{employee.ID}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </RadioGroup.Label>
                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-2xl text-gray-500">

                                                </RadioGroup.Description>
                                                <RadioGroup.Description as="span" className="mt-6 text-sm font-light text-gray-900">
                                                    <dl className='grid grid-cols-2 text-xs'>
                                                        <dt>NIP</dt>
                                                        <dd>{employee.employeeIdentityNumber}</dd>
                                                        <dt>NIK</dt>
                                                        <dd>{employee.nationalIdentityNumber}</dd>
                                                    </dl>
                                                    <div className='absolute right-1.5 bottom-1.5'>
                                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                            Akses
                                                        </span>
                                                    </div>
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        <IconSolid.CheckCircleIcon
                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                            aria-hidden="true"
                                        />
                                        <div
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'absolute -inset-px rounded-lg pointer-events-none'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Fragment>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>


                <Pagination
                    pagination={pagination}
                    onPaginationChange={({ page, show }) => {
                        setCurrentPage(page)
                        setPageSize(show)
                    }}
                />
            </div>

        </div>
    )
}