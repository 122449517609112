import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseEmployeeAttendanceLog({
    sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword,
    employeeID, date, 
    onSuccess
}) {
    const path = `/organization/employee-attendance-log`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, keyword, currentPage, date], () => requestGet(path, {
        sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(','), 
        employeeID, date,
    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useCreateEmployeeAttendanceLog({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ number, employeeID, latitude, longitude, date, time, type, note }) => requestPost(`/organization/employee-attendance-log`, { number, employeeID, latitude, longitude, note, date, time, type }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}



export function useDeleteEmployeeAttendanceLog(ID, { onSuccess = () => null, onError }) {
    const path = `/organization/employee-attendance-log/${ID}`
    const mutation = useMutation(({ }) => requestDelete(path, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}