import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import { useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { useDetailAccount } from 'Data/Authentication/account'
import { transformResponseToSingle } from 'Utility/formatter'
import { humanizeDate, humanizeDateTime } from 'Utility/humanize'
import ModalUpdatePassword from './ModalUpdatePassword'
import ModalAssignSuperAdministrator from './ModalAssignSuperAdministrator'
import ModalInvokeSuperAdministrator from './ModalInvokeSuperAdministrator'
import { ActivityLog } from './ActivityLog'
import ModalInvokeEmpoloyee from './ModalInvokeEmpoloyee'
import ModalAssignEmployee from './ModalAssignEmployee'

export default function DetailAccount() {
    const { id } = useParams()
    const [actionState, setActionState] = useState(null)
    const detailAccount = useDetailAccount(id, { expands: ['role'] });
    const account = transformResponseToSingle(detailAccount.data)

    if (detailAccount.isLoading) {
        return null
    }


    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <main className="py-10 w-full">
                    {/* Page header */}
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div className="flex-shrink-0">
                                <div className="relative">
                                    {account.avatar ?
                                        <img
                                            className="h-16 w-16 rounded-full"
                                            src={account.avatar.fullURL}
                                            alt=""
                                        /> :
                                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                            <span className="font-medium leading-none text-white">
                                                {account.email.split(" ").map((part) => `${part[0]}`.toLocaleUpperCase()).join("")}
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div>
                                <h1 className="text-lg md:text-2xl font-bold text-gray-900">{account.email}</h1>
                                <p className="text-sm font-medium text-gray-500">
                                    Created {' '}
                                    on <time dateTime={account.created}>{humanizeDate(account.created)}</time>
                                </p>
                            </div>

                        </div>
                        <div className='flex flex-wrap gap-2 mt-4 md:mt-0'>
                            {account.role.superAdministrator && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-indigo-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900 hidden md:block">
                                        Super Administrator
                                    </span>
                                    <span className='ml-3.5 block md:hidden'>SA</span>
                                </button>
                            )}
                            {account.role.administrator && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-red-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900 hidden md:block">
                                        Super Administrator
                                    </span>
                                    <span className='ml-3.5 block md:hidden'>Admin</span>
                                </button>
                            )}
                            {account.role.employee && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900">Employee</span>
                                </button>
                            )}
                        </div>
                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                            <button
                                type="button"
                                onClick={() => setActionState('update-password')}
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                Update Password
                            </button>
                        </div>
                    </div>

                    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {/* Description list*/}

                            {account.role.superAdministrator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Super Administrator
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Super administrator access information.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Assined at</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <div className=''>{humanizeDateTime(account.role.superAdministrator.created)}</div>
                                                        <div className='font-light text-xs'>{account.role.superAdministrator.created}</div>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => setActionState('invoke-super-administrator')} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Invoke</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {!account.role.superAdministrator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Super Administrator
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Super administrator access information.</p>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => setActionState('assign-super-administrator')} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Assign Role</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {account.role.employee === null ? null : (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Employee
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Full Name
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.name}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Phone
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.phone ?? '-'}
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Birth Place
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.birth.place ?? '-'}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Birth Date
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.birth.date ? humanizeDate(account.role.employee.roleEntity.birth.date) : '-'}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-2 col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Address
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.domicileAddress ?? '-'}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="px-4 py-5 sm:px-6 border-t">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Employee
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Position
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.position.name}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Position Group
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.position.group.name}
                                                    </dd>
                                                </div> <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        NIP
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.employeeIdentityNumber ?? '-'}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        NIK
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.nationalIdentityNumber ?? '-'}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => setActionState('invoke-employee')} className="w-0 flex-1 flex">
                                                <div
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Invoke</span>
                                                </div>
                                            </button>
                                            <Link to={`/employee/${account.role.employee.roleEntity.ID}/update`} className="-ml-px w-0 flex-1 flex">
                                                <div
                                                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Data Karyawan</span>
                                                </div>
                                            </Link>
                                            <Link to={`/employee/${account.role.employee.roleEntity.ID}/update`} className="-ml-px w-0 flex-1 flex">
                                                <div
                                                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Ubah Data Karyawan</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {!account.role.employee && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Employee
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => setActionState('assign-employee')} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Assign Role</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>

                        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                            <ActivityLog />
                        </section>
                    </div>
                </main>
            </div>
            <ModalUpdatePassword
                account={account}
                open={actionState === 'update-password'}
                onClose={() => setActionState(null)}
                onConfirm={() => setActionState(null)}
            />
            <ModalAssignSuperAdministrator
                account={account}
                open={actionState === 'assign-super-administrator'}
                onClose={() => setActionState(null)}
                onConfirm={() => {
                    detailAccount.refetch()
                    setActionState(null)
                }}
            />
            <ModalAssignEmployee
                account={account}
                open={actionState === 'assign-employee'}
                onClose={() => setActionState(null)}
                onConfirm={() => {
                    detailAccount.refetch()
                    setActionState(null)
                }}
            />
            <ModalInvokeSuperAdministrator
                account={account}
                open={actionState === 'invoke-super-administrator'}
                onClose={() => setActionState(null)}
                onConfirm={() => {
                    detailAccount.refetch()
                    setActionState(null)
                }}
            />
            <ModalInvokeEmpoloyee
                account={account}
                open={actionState === 'invoke-employee'}
                onClose={() => setActionState(null)}
                onConfirm={() => {
                    detailAccount.refetch()
                    setActionState(null)
                }}
            />
        </DashboardLayout>

    )
}
