import * as IconSolid from '@heroicons/react/24/solid'
import { useCallback, useEffect, useState } from 'react'
import DashboardLayout from 'UI/Layout/DashboardLayout'

import classNames from 'classnames'
import { Link, useSearchParams } from 'react-router-dom'
import { transformResponseToList, transformResponseToPagination } from 'Utility/formatter'
import { Pagination } from 'UI/Component'
import TableLoader from 'UI/Component/TableLoader'
import useDebounce from 'Utility/hook'
import { Icon, Subtitle } from '@tremor/react'
import { IconOutline } from 'UI/Component/Icon'
import { LocationInformation } from 'UI/Component/Map'
import { humanizeCurrency, humanizeNumber } from 'Utility/humanize'
import { useBrowseCourier } from '../../Data/api'

export default function BrowseCourier() {
    const [actionState, setActionState] = useState(null)
    const [queryKeyword, setQueryKeyword] = useState('')
    const debounceQueryKeyword = useDebounce(queryKeyword, 500);
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page')
    const show = searchParams.get('show')
    const keyword = searchParams.get('keyword')

    const browseCourier = useBrowseCourier({ expands: ['statistic'], currentPage: searchParams.get('page'), pageSize: show, keyword: debounceQueryKeyword })
    const pagination = transformResponseToPagination(browseCourier.data)
    const couriers = transformResponseToList(browseCourier.data)

    const search = useCallback((event) => {
        browseCourier.refetch()
        event.preventDefault()
    })
    useEffect(() => {
        browseCourier.refetch()
    }, [page, show])



    return (
        <DashboardLayout className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Organisasi
                    </h1>
                    <Link to="/courier" className='text-indigo-600'>Daftar Kurir</Link>
                </div>
                <div className="mt-4 flex sm:mt-0 sm:ml-4">
                    <button
                        onClick={browseCourier.refetch}
                        type="button"
                        className="order-1 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-0 sm:ml-0"
                    >
                        Muat Ulang <IconSolid.ArrowPathIcon className={classNames(
                            "ml-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 border-2 p-1 rounded-full",
                            browseCourier.isFetching ? 'animate-spin' : ''
                        )} />
                    </button>
                </div>
            </div>

            <div className='m-10'>
                <div>
                    <form onSubmit={search}>
                        <div className="flex-1 flex justify-center lg:justify-end ">
                            <div className="w-full px-2 lg:px-6 ">
                                <label htmlFor="search" className="sr-only">
                                    Pencarian Kurir
                                </label>
                                <div className="relative text-indigo-200 focus-within:text-gray-400">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-indigo-800" aria-hidden="true" />
                                    </div>
                                    <input
                                        value={queryKeyword}
                                        className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-900 placeholder-indigo-800 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                                        placeholder="Cari nama pemesan, alamat atau kata kunci lainnya"
                                        type="search"
                                        onChange={({ target: { value } }) => setQueryKeyword(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    UID
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Nama
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Income<br />
                                    <span className='text-gray-400'>(Today)</span>
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Trip Count<br />
                                    <span className='text-gray-400'>(Today)</span>
                                </th>
                                <th scope="col" className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Trip Millage<br />
                                    <span className='text-gray-400'>(Today)</span>
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 text-right sm:pr-6">
                                    Lokasi Terakhir
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className='sr-only'>Action</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {browseCourier.isLoading ? <TableLoader rowSize={10} columnSize={3} /> :
                                couriers.map((courier) =>
                                    <tr key={courier.ID}>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <Subtitle>
                                                {courier.ID}
                                            </Subtitle>
                                        </td>
                                        <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <Subtitle>
                                                {courier.name}
                                            </Subtitle>
                                        </td>
                                        <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <div>
                                                {humanizeCurrency(courier.statistic.money.income.today)}
                                            </div>
                                        </td>
                                        <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <div>
                                                {humanizeNumber(courier.statistic.trip.count.today, 0, 0)}
                                            </div>
                                        </td>
                                        <td className="hidden md:table-cell w-full max-w-0 py-4 pl-4 pr-3 space-x-2 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                            <div>
                                                {humanizeNumber(courier.statistic.trip.distance.today)} <span className='text-gray-400'>Km</span>
                                            </div>
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <div className='flex justify-end text-left'>
                                                {!courier.lastLocation ? null :
                                                    <LocationInformation
                                                        object={courier}
                                                    />
                                                }
                                            </div>
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <div className='flex flex-row gap-2 justify-end'>
                                                <Link to={`/courier/${courier.ID}`}>
                                                    <span className='text-indigo-600 hover:text-indigo-900 hidden md:block shadow border-indigo-600 p-2 rounded-xl'>
                                                        View
                                                    </span>
                                                    <div className='bg-indigo-600 rounded-full p-2 flex justify-center items-center md:hidden'>
                                                        <IconSolid.PencilSquareIcon className='w-4 h-4 text-white ' />
                                                    </div>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <Pagination pagination={pagination} onPaginationChange={({ page, show }) => setSearchParams({ page, show })} />
                </div>
            </div>
        </DashboardLayout>

    )
}
