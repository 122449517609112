import create from 'zustand'
import { persist } from 'zustand/middleware'

export const usePinnedBrand = create(persist(
    (set, get) => ({
        pinnedBrands: [],
        pinned: (brand) => {
            let pinnedBrands = [...get().pinnedBrands];
            if (pinnedBrands.find(search => search.ID == brand.ID)) {
                return true
            }
            return false
        },
        pin: (brand) => {
            let pinnedBrands = [...get().pinnedBrands];
            if (pinnedBrands.find(search => search.ID == brand.ID)) {
                return
            }
            pinnedBrands.push(brand)
            return set({ pinnedBrands })
        },
        unpin: (brand) => {
            let pinnedBrands = [...get().pinnedBrands];
            if (pinnedBrands.find(search => search.ID == brand.ID)) {
                pinnedBrands = [...pinnedBrands.filter(search => search.ID != brand.ID)]
            }
            return set({ pinnedBrands })
        },
        togglePin: (brand) => {
            let pinnedBrands = [...get().pinnedBrands];
            if (pinnedBrands.find(search => search.ID == brand.ID)) {
                pinnedBrands = [...pinnedBrands.filter(search => search.ID != brand.ID)]
            } else {
                if (pinnedBrands.length <= 5) {
                    pinnedBrands.push(brand)
                }
            }
            return set({ pinnedBrands })
        }
    }),
    {
        name: 'pinned-brands', // unique name
        getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
))