
import { Legend } from "@tremor/react"
import classNames from "classnames"
import { IconOutline, IconSolid } from "UI/Component/Icon"
import { LocationInformation } from "UI/Component/Map"
import { humanizeCurrency, humanizeDate, humanizeDateTime, humanizeRelativeFromDate, humanizeRelativeToDate } from "Utility/humanize"
import { useDetailDeliveryContext } from "./context"

const currencies = ['CAD', 'USD', 'AUD', 'EUR', 'GBP']
const navigation = {
    categories: [
        {
            name: 'Women',
            featured: [
                { name: 'Sleep', href: '#' },
                { name: 'Swimwear', href: '#' },
                { name: 'Underwear', href: '#' },
            ],
            collection: [
                { name: 'Everything', href: '#' },
                { name: 'Core', href: '#' },
                { name: 'New Arrivals', href: '#' },
                { name: 'Sale', href: '#' },
            ],
            categories: [
                { name: 'Basic Tees', href: '#' },
                { name: 'Artwork Tees', href: '#' },
                { name: 'Bottoms', href: '#' },
                { name: 'Underwear', href: '#' },
                { name: 'Accessories', href: '#' },
            ],
            brands: [
                { name: 'Full Nelson', href: '#' },
                { name: 'My Way', href: '#' },
                { name: 'Re-Arranged', href: '#' },
                { name: 'Counterfeit', href: '#' },
                { name: 'Significant Other', href: '#' },
            ],
        },
        {
            name: 'Men',
            featured: [
                { name: 'Casual', href: '#' },
                { name: 'Boxers', href: '#' },
                { name: 'Outdoor', href: '#' },
            ],
            collection: [
                { name: 'Everything', href: '#' },
                { name: 'Core', href: '#' },
                { name: 'New Arrivals', href: '#' },
                { name: 'Sale', href: '#' },
            ],
            categories: [
                { name: 'Artwork Tees', href: '#' },
                { name: 'Pants', href: '#' },
                { name: 'Accessories', href: '#' },
                { name: 'Boxers', href: '#' },
                { name: 'Basic Tees', href: '#' },
            ],
            brands: [
                { name: 'Significant Other', href: '#' },
                { name: 'My Way', href: '#' },
                { name: 'Counterfeit', href: '#' },
                { name: 'Re-Arranged', href: '#' },
                { name: 'Full Nelson', href: '#' },
            ],
        },
    ],
    pages: [
        { name: 'Company', href: '#' },
        { name: 'Stores', href: '#' },
    ],
}
const products = [
    {
        id: 1,
        name: 'Nomad Tumbler',
        description:
            'This durable and portable insulated tumbler will keep your beverage at the perfect temperature during your next adventure.',
        href: '#',
        price: '35.00',
        status: 'Preparing to ship',
        step: 1,
        date: 'March 24, 2021',
        datetime: '2021-03-24',
        address: ['Floyd Miles', '7363 Cynthia Pass', 'Toronto, ON N3Y 4H8'],
        email: 'f•••@example.com',
        phone: '1•••••••••40',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-03-product-01.jpg',
        imageAlt: 'Insulated bottle with white base and black snap lid.',
    },
]


export default function DetailDeliverySummary() {
    const { delivery } = useDetailDeliveryContext()

    const IconGoodCategory = ({
        'Komputer': IconSolid.ComputerDesktopIcon
    }[delivery.goodCategory.name] ?? IconSolid.CubeIcon)

    const step = ({
        'pending': 0,
        'open': 1,
        'active': 2,
        'finish': 3,
        'cancel': 3
    }[delivery.state.status.value])

    return (
        <div className="bg-gray-50">
            {/* Mobile menu */}
            <main className="max-w-2xl mx-auto pt-8 pb-24 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="px-4 space-y-2 sm:px-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0">
                    <div className="flex sm:items-baseline sm:space-x-4">
                        <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Order #{delivery.ID}</h1>
                    </div>
                    <p className="text-sm text-gray-600 flex flex-col">
                        <span>
                            Order placed{' '}
                            <time dateTime={delivery.created} className="font-medium text-gray-900">
                                {humanizeDateTime(delivery.created)}
                            </time>
                        </span>
                        <span className="text-right">
                            {humanizeRelativeFromDate(delivery.created)}
                        </span>
                    </p>

                    <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden">
                        View invoice<span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>

                {/* Products */}
                <section aria-labelledby="products-heading" className="mt-6">
                    <h2 id="products-heading" className="sr-only">
                        Products purchased
                    </h2>

                    <div className="space-y-8">
                        {products.map((product) => (
                            <div
                                key={product.id}
                                className="bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg"
                            >
                                <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                    <div className="sm:flex lg:col-span-7">
                                        <div className="flex-shrink-0 w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-none sm:w-40 sm:h-40 shadow-md">
                                            <IconGoodCategory
                                                className="w-full h-full object-center object-cover sm:w-full sm:h-full p-10"
                                            />
                                        </div>


                                        <div className="mt-6 sm:mt-0 sm:ml-6">
                                            <h3 className="text-base font-medium text-gray-900">
                                                {delivery.goodCategory.name}
                                            </h3>
                                            <p className="mt-2 text-sm font-medium text-gray-900">
                                                {humanizeCurrency(delivery.price)}
                                            </p>
                                            <p className="mt-3 text-sm text-gray-500">

                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt-6 lg:mt-0 lg:col-span-5">
                                        <dl className="grid grid-cols-2 gap-x-6 gap-y-4 text-sm">
                                            <div>
                                                <dt className="font-medium text-gray-900">Alamat asal</dt>
                                                <dd className="mt-3 text-gray-500">
                                                    <span className="block">
                                                        <LocationInformation
                                                            object={delivery.route}
                                                            selector={route => route.origin}
                                                            isHistory={false}
                                                        />
                                                    </span>
                                                    <span className="block">
                                                        {delivery.route.origin.note || '-'}
                                                    </span>
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="font-medium text-gray-900">Alamat Tujuan</dt>
                                                <dd className="mt-3 text-gray-500">
                                                    <span className="block">
                                                        <LocationInformation
                                                            object={delivery.route}
                                                            selector={route => route.destination}
                                                            isHistory={false}
                                                        />
                                                    </span>
                                                    <span className="block">
                                                        {delivery.route.destination.note || '-'}
                                                    </span>
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="font-medium text-gray-900">Informasi Kurir</dt>
                                                <dd className="mt-3 text-gray-500 space-y-3">
                                                    {!delivery.route.assigned ?
                                                        <p>-</p> :
                                                        <>
                                                            <p>{delivery.route.assigned.courier.name}</p>
                                                            <span>
                                                                <LocationInformation
                                                                    object={delivery.route.assigned.courier}
                                                                />
                                                            </span>
                                                        </>
                                                    }
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                <div className="flex justify-end mb-2">
                                    <Legend
                                        categories={["Current Location/Absolute", "Past Location"]}
                                        colors={["green", "rose"]}
                                        marginTop="mt-3"
                                    />
                                </div>

                                <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
                                    <h4 className="sr-only">Status</h4>

                                    <div className="mt-6" aria-hidden="true">
                                        <div className="bg-gray-200 rounded-full overflow-hidden">
                                            <div
                                                className="h-2 bg-indigo-600 rounded-full"
                                                style={{
                                                    width: `calc((${step} * 2 + 1) / 8 * 100%)`
                                                }}
                                            />
                                        </div>
                                        <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                                            <div className="text-indigo-600">Order place</div>
                                            <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                                                Courier Matching
                                            </div>
                                            <div className={classNames(step > 1 ? 'text-indigo-600' : '', 'text-center')}>
                                                Delivering
                                            </div>
                                            <div className={classNames(step > 2 ? 'text-indigo-600' : '', 'text-right')}>
                                                Finish
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Billing */}

            </main>

            <footer aria-labelledby="footer-heading" className="bg-white border-t border-gray-200">
                <div className="max-w-2xl mx-auto pt-8 pb-24 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                    <section aria-labelledby="summary-heading" className="mt-16">
                        <h2 id="summary-heading" className="sr-only">
                            Billing Summary
                        </h2>

                        <div className="bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg lg:px-8 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
                            <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                                <div>
                                    <dt className="font-medium text-gray-900">Billing address</dt>
                                    <dd className="mt-3 text-gray-500">
                                        <span className="block">Floyd Miles</span>
                                        <span className="block">7363 Cynthia Pass</span>
                                        <span className="block">Toronto, ON N3Y 4H8</span>
                                    </dd>
                                </div>
                                <div>
                                    <dt className="font-medium text-gray-900">Payment information</dt>
                                    <div className="mt-3">
                                        <dd className="-ml-4 -mt-4 flex flex-wrap">
                                            <div className="ml-4 mt-4 flex-shrink-0">
                                                <svg
                                                    aria-hidden="true"
                                                    width={36}
                                                    height={24}
                                                    viewBox="0 0 36 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-auto"
                                                >
                                                    <rect width={36} height={24} rx={4} fill="#224DBA" />
                                                    <path
                                                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                                        fill="#fff"
                                                    />
                                                </svg>
                                                <p className="sr-only">Visa</p>
                                            </div>
                                            <div className="ml-4 mt-4">
                                                <p className="text-gray-900">Ending with 4242</p>
                                                <p className="text-gray-600">Expires 02 / 24</p>
                                            </div>
                                        </dd>
                                    </div>
                                </div>
                            </dl>

                            <dl className="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:col-span-5">
                                <div className="pb-4 flex items-center justify-between">
                                    <dt className="text-gray-600">Subtotal</dt>
                                    <dd className="font-medium text-gray-900">$72</dd>
                                </div>
                                <div className="py-4 flex items-center justify-between">
                                    <dt className="text-gray-600">Shipping</dt>
                                    <dd className="font-medium text-gray-900">$5</dd>
                                </div>
                                <div className="py-4 flex items-center justify-between">
                                    <dt className="text-gray-600">Tax</dt>
                                    <dd className="font-medium text-gray-900">$6.16</dd>
                                </div>
                                <div className="pt-4 flex items-center justify-between">
                                    <dt className="font-medium text-gray-900">Order total</dt>
                                    <dd className="font-medium text-indigo-600">$83.16</dd>
                                </div>
                            </dl>
                        </div>
                    </section>
                </div>

            </footer>
        </div>
    )
}