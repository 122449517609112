import * as IconSolid from '@heroicons/react/24/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { humanizeCurrency, humanizeDate, humanizeDateTime, humanizeRelativeFromDate } from 'Utility/humanize';
import { useDetailEmployeeContext } from './context';

export default function DetailEmployeeBrowseSalary() {
    const { employee, setActionState, employeeSalaries } = useDetailEmployeeContext()

    return (
        <div className="mt-8 max-w-3xl mx-auto min-w-full">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            Manajemen Gaji
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Sejarah perubahan gaji karyawan dari yang terbaru hingga terlama
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            onClick={() => setActionState('add-employee-salary')}
                            type="button"
                            className="inline-flex items-center gap-2 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            <IconSolid.BanknotesIcon className='w-4 h-4' />
                            Buat Gaji Baru
                        </button>
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Gaji
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Komponen
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {employeeSalaries.map((employeeSalary) => (
                                            <tr key={employeeSalary.ID}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">{humanizeRelativeFromDate(employeeSalary.created)}</div>
                                                            <div className="text-gray-500">{humanizeDateTime(employeeSalary.created)}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="text-gray-900">{humanizeCurrency(employeeSalary.totalAmount)}</div>
                                                    <div className="text-gray-500 text-xs flex flex-row gap-2">
                                                        <IconOutline.TicketIcon className='w-4 h-4' />
                                                        {humanizeCurrency(employeeSalary.baseAmount)}
                                                    </div>
                                                    <div className="text-gray-500 text-xs flex flex-row gap-2">
                                                        <IconOutline.TicketIcon className='w-4 h-4' />
                                                        {humanizeCurrency([0, ...employeeSalary.items.map(item => item.amount)].reduce((total, current) => current + total))}
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div>

                                                        <ul role="list" className="divide-y divide-gray-200">
                                                            <li className="py-4">
                                                                <div className="flex space-x-3">
                                                                    <IconOutline.BanknotesIcon className="h-4 w-4 rounded-full" />
                                                                    <div className="flex-1 space-y-1">
                                                                        <div className="flex items-center justify-between">
                                                                            <h3 className="text-sm font-medium">Gaji Utama</h3>
                                                                            <p className="text-sm text-gray-500">{humanizeCurrency(employeeSalary.baseAmount)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {employeeSalary.items.map(item => (
                                                                <li className="py-4" key={item.ID}>
                                                                    <div className="flex space-x-3 ">
                                                                        <IconOutline.BanknotesIcon className="h-4 w-4 rounded-full" />
                                                                        <div className="flex-1 space-y-1">
                                                                            <div className="flex items-center justify-between">
                                                                                <h3 className="text-sm font-medium">{item.name}</h3>
                                                                                <p className="text-sm text-gray-500">{humanizeCurrency(item.amount)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </td>

                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}