import { requestDelete, requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseEmployeeBankAccount({
    sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword,
    employeeID,
    onSuccess
}) {
    const path = `/organization/employee-bank-account`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, keyword, currentPage], () => requestGet(path, {
        sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(','),
        employeeID
    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useCreateEmployeeBankAccount({ onSuccess = () => null, onError }) {
    const mutation = useMutation(({ number, bankID, employeeID }) => requestPost(`/organization/employee-bank-account`, { number, bankID, employeeID }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}



export function useDeleteEmployeeBankAccount(ID, { onSuccess = () => null, onError }) {
    const path = `/organization/employee-bank-account/${ID}`
    const mutation = useMutation(({ }) => requestDelete(path, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}