/* This example requires Tailwind CSS v2.0+ */
import { useRef, useState } from 'react'
import { ArrowPathIcon, TruckIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import { transformResponseError, transformResponseToSingle } from 'Utility/formatter'
import DashboardLayout from 'UI/Layout/DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { InputText, InputTextArea, Selection } from 'UI/Component/Input'
import classNames from 'classnames'
import { useCreateEmployee } from 'Data/Organization/employee'
import EmployeePositionChooser from './EmployeePositionChooser'
import { useBrowseMaritalStatus } from 'Data/Organization/marital-status'
import { useBrowseEducationLevel } from 'Data/Organization/education-level'


export default function CreateEmployee({ }) {
    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)
    const [name, setName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [birthPlace, setBirthPlace] = useState('')
    const [domicileAddress, setDomicileAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [nationalIdentityNumber, setNationalIdentityNumber] = useState('')
    const [employeeIdentityNumber, setEmployeeIdentityNumber] = useState('')
    const [employeePosition, setEmployeePosition] = useState('')
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null)
    const [selectedEducationLevel, setSelectedEducationLevel] = useState(null)


    const createEmployee = useCreateEmployee({
        onSuccess: (response) => {
            const employee = transformResponseToSingle(response)

            navigate(`/employee/${employee.ID}`)
        }
    })
    const error = transformResponseError(createEmployee.error)

    const submit = (event) => {
        createEmployee.mutate({
            name, birthDate, birthPlace,
            domicileAddress, phone,
            nationalIdentityNumber, employeeIdentityNumber,
            employeePositionID: employeePosition?.ID,
            maritalStatusID: selectedMaritalStatus?.ID,
            educationLevelID: selectedEducationLevel?.ID
        })
        event.preventDefault()
    }

    return (
        <DashboardLayout className="">
            <div className='m-10'>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                <UserPlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-0  sm:text-left w-full mx-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Create new Employee
                                </h3>


                                <div className='mt-2 space-y-2 py-5 flex flex-col gap-5'>

                                    <div className={classNames(error['body.truckModelID'] ? 'ring-1 p-2 rounded text-red-900 ring-rose-600 border-rose-600' : '')}>
                                        <h4 className='border-b mb-4 pb-2 pt-8 leading-4'>
                                            Person Information<br />
                                            <span className='text-sm font-light'>Person basic information</span>
                                        </h4>
                                    </div>

                                    <InputText
                                        label={'Name *'}
                                        value={name}
                                        onChange={value => setName(value)}
                                        placeholder="Employee Name"
                                        errorMessage={error['body.name']}
                                    />

                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        <InputText
                                            label={'Birth Date'}
                                            value={birthDate}
                                            type="date"
                                            onChange={value => setBirthDate(value)}
                                            placeholder="dd/mm/yyyy"
                                            errorMessage={error['body.birthDate']}
                                        />
                                        <InputText
                                            label={'Birth Place'}
                                            value={birthPlace}
                                            onChange={value => setBirthPlace(value)}
                                            placeholder="Place"
                                            errorMessage={error['body.birthPlace']}
                                        />
                                    </div>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <InputText
                                            label={'Phone'}
                                            value={phone}
                                            onChange={value => setPhone(value)}
                                            placeholder="Phone number"
                                            errorMessage={error['body.phone']}
                                        />
                                    </div>
                                    <InputTextArea
                                        label={'Adddress'}
                                        value={domicileAddress}
                                        onChange={value => setDomicileAddress(value)}
                                        placeholder="Please insert employee domicile address"
                                        errorMessage={error['body.domicileAddress']}
                                    />



                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        <Selection
                                            label="Status Kawin"
                                            useQuery={useBrowseMaritalStatus}
                                            value={selectedMaritalStatus}
                                            labelSelector={item => item?.code}
                                            onChange={value => setSelectedMaritalStatus(value)}

                                        />
                                        <Selection
                                            label="Pendidikan Terakhir"
                                            useQuery={useBrowseEducationLevel}
                                            value={selectedEducationLevel}
                                            labelSelector={item => item?.name}
                                            onChange={value => setSelectedEducationLevel(value)}

                                        />
                                    </div>


                                    <div className={classNames(error['body.truckModelID'] ? 'ring-1 p-2 rounded text-red-900 ring-rose-600 border-rose-600' : '')}>
                                        <h4 className='border-b mb-4 pb-2 pt-8 leading-4'>
                                            Employee Information<br />
                                            <span className='text-sm font-light'>Employee basic information</span>
                                        </h4>
                                    </div>


                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        <InputText
                                            label={'NIK'}
                                            value={nationalIdentityNumber}
                                            onChange={value => setNationalIdentityNumber(value)}
                                            placeholder="Please input NIK Number"
                                            errorMessage={error['body.nationalIdentityNumber']}
                                        />
                                        <InputText
                                            label={'NIP'}
                                            value={employeeIdentityNumber}
                                            onChange={value => setEmployeeIdentityNumber(value)}
                                            placeholder="Please input NIP Number"
                                            errorMessage={error['body.employeeIdentityNumber']}
                                        />
                                    </div>

                                    <EmployeePositionChooser
                                        value={employeePosition}
                                        onChange={value => setEmployeePosition(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                        <form onSubmit={submit}>
                            <button
                                type="submit"


                                disabled={createEmployee.isLoading}
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"

                            >

                                {createEmployee.isLoading ?
                                    <span className='flex flex-row gap-2 items-center'><ArrowPathIcon className='h-3 w-3 animate-spin' />  Saving..</span> :
                                    'Register'
                                }

                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                ref={cancelButtonRef}
                                onClick={() => navigate('/employee')}
                            >
                                Cancel
                            </button>

                        </form>
                    </div>
                </div>
            </div>
        </DashboardLayout>

    )
}