import { Menu, Transition } from "@headlessui/react";
import * as IconOutline from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { useAuth } from 'App'
import classNames from "classnames";
import { authenticationProvider } from "Provider/Authentication";



export default function UserPanel({ onLogout }) {
    const { user } = useAuth()
    return (
        <Menu as="div" className="flex-shrink-0 w-full group block">
            <div className='flex items-center p-1'>
                <Menu.Button className="group p-2 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="min-w-0 flex-1 flex items-center space-x-3">
                        <span className="block flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                        </span>
                        <span className="block min-w-0 flex-1">
                            <span className="block text-xs font-medium text-gray-900 truncate">{user.email}</span>
                            <span className="block text-sm font-medium text-gray-500 truncate">
                                {({
                                    'Super-Administrator': 'Administrator',
                                    'Employee': 'Karyawan'
                                }[authenticationProvider.role()])}
                            </span>
                        </span>
                    </span>
                    <span className="flex-shrink-0 h-10 w-10 inline-flex items-center justify-center">
                        <IconOutline.ChevronUpIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500 border-2 rounded-full" aria-hidden="true" />
                    </span>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-bottom-left absolute left-4 bottom-16 mb-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="px-4 py-3">
                        <p className="text-sm">Masuk sebagai</p>
                        <p className="text-sm font-medium text-gray-900 truncate">{user.email}</p>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm line-through text-gray-300'
                                    )}
                                >
                                    Account settings
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm line-through text-gray-300'
                                    )}
                                >
                                    Support
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm line-through text-gray-300'
                                    )}
                                >
                                    License
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => onLogout(true)}
                                    type="submit"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full text-left px-4 py-2 text-sm'
                                    )}
                                >
                                    Keluar
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}