import { requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useReportMillage({ startDate, endDate, status, truckID, onSuccess }) {
    const path = `/information/report/millage`
    const data = useQuery([...(path.split('/')), startDate, endDate, status, truckID], () => requestGet(path, { startDate, endDate, status, truckID}), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useReportFarmYieldSize({ startDate, endDate, farmID, onSuccess }) {
    const path = `/information/report/farm-yield-size`
    const data = useQuery([...(path.split('/')), startDate, endDate, farmID], () => requestGet(path, { startDate, endDate, farmID}), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
