/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon } from '@heroicons/react/solid'
import * as IconOutline from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'App'
import Lottie from "lottie-react";

export default function NotFound() {
    const navigate = useNavigate()
    const { signOut } = useAuth()
    const links = [
        {
            title: 'Masuk Ulang', description: 'Mencoba masuk lagi, jika sebelumnya sudah login akan dikeluarkan dulu', icon: IconOutline.ArrowLeftOnRectangleIcon,
            action: () => {
                signOut()
                navigate('/', { replace: true })
            }
        },
    ]
    return (
        <div className="bg-white">
            <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex pt-16 items-center justify-center">
                    <img width={120} src={require('Asset/Logo/company-logo.png')} />
                </div>
                <div className="max-w-xl mx-auto py-16 sm:py-24">
                    <div className="text-center">
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                            Error 404, Halaman tidak ditemukan.
                        </h1>
                        <p className="mt-2 text-lg text-gray-500">Halaman yang coba anda kunjungi kemungkinan sudah tidak ada atau sedang masa pengembangan. Silahkan kontak admin jika anda yakin ada.</p>
                        <div className='flex justify-center items-center'>
                            <Lottie animationData={require('Asset/Lottie/404.json')} loop={true} style={{ width: 400 }} />
                        </div>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Halaman rekomendasi</h2>
                        <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                            {links.map((link, linkIdx) => (
                                <li key={linkIdx} onClick={link.action} className="relative py-6 flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                                        <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                                            <link.icon className="h-6 w-6 text-indigo-700" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <h3 className="text-base font-medium text-gray-900">
                                            <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <button className="focus:outline-none">
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    {link.title}
                                                </button>
                                            </span>
                                        </h3>
                                        <p className="text-base text-gray-500">{link.description}</p>
                                    </div>
                                    <div className="flex-shrink-0 self-center">
                                        <IconOutline.ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-8">
                            <Link to="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                                atau balik ke beranda<span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
                    <p className="text-base text-gray-400">&copy; <a href='https://twitter.com/andryluthfi' target="_blank">andryluthfi</a>, Inc. All rights reserved.</p>
                    <div className="mt-6 flex justify-center space-x-8 md:mt-0">

                    </div>
                </div>
            </footer>
        </div>
    )
}
