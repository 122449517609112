import { useState } from "react"
import { LineSegment } from "@phosphor-icons/react";
import { ActionDialog } from "UI/Component/Dialog";
import { InputText } from "UI/Component/Input";
import { transformResponseError, transformResponseToSingle } from "Utility/formatter";
import { Button } from "@tremor/react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { useCreateEmployeePositionGroup } from "../../Data/API";
import SwitchBoolean from "UI/Component/Selection/SwitchBoolean";

export default function DialogCreateEmployeePositionGroup({ open, onClose, onConfirm }) {
    const [name, setName] = useState('')
    const [isHumanResourceManager, setIsHumanResourceManager] = useState(false)
    const [isOperationalStaff, setIsOperationalStaff] = useState(false)

    const createEmployeePositionGroup = useCreateEmployeePositionGroup({
        onSuccess: (data) => {
            const employeePosition = transformResponseToSingle(data)
            onConfirm(employeePosition)

        }
    })
    const error = transformResponseError(createEmployeePositionGroup.error)

    return (
        <ActionDialog
            icon={LineSegment}
            open={open} onClose={onClose}
            title="Tambah Posisi Baru"
            onConfirm={() => createEmployeePositionGroup.mutateAsync({ name, isHumanResourceManager, isOperationalStaff })}
            confirm={(onActionConfirm) => (
                <Button type="submit" onClick={() => onActionConfirm()} loading={createEmployeePositionGroup.isLoading} loadingText="Menyimpan">
                    Simpan
                </Button>
            )}
        >
            <div className="min-h-[200px]">
                <div className="mt-4 grid grid-cols-1 md:grid-cols-1 gap-2">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Nama Grup Posisi</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <InputText
                                value={name}
                                onChange={value => setName(value)}
                                errorMessage={error['body.name']}
                            />
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Hak Manajer HRD</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <SwitchBoolean
                                value={isHumanResourceManager}
                                onChange={setIsHumanResourceManager}
                            />
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Hak Staf Operasional</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <SwitchBoolean
                                value={isOperationalStaff}
                                onChange={setIsOperationalStaff}
                            />
                        </dd>
                    </div>
                </div>
            </div>
        </ActionDialog>
    )
}
