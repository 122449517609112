import { useId } from "react"
import classNames from "classnames"
import { useState } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { transformResponseToList } from "Utility/formatter"
import useDebounce from "Utility/hook"
import { useCurrency as currencyFormatter } from 'react-hook-currency';
import { humanizeNumber } from "Utility/humanize"
import { IconSolid } from "./Icon"


export function InputFormat({ label, value, onChange, placeholder, errorMessage = null, layoutProps, numberProps = null }) {
    const formatterProps = numberProps || { style: 'decimal', precision: 0 }
    const precision = formatterProps.precision || 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { onClick, onChange: onChangeNative, onKeyDown, format, toNumber, } = currencyFormatter(formatterProps);
    const [nativeValue, setNativeValue] = useState(() => format(`${humanizeNumber(value / ((Math.pow(10, 2 - precision))))}`));

    return (
        <InputText
            label={label}
            onChange={event => {
                onChangeNative(event);
                setNativeValue(event.target.value);
                onChange(toNumber(event.target.value))
            }}
            onChangeNative={true}
            onKeyDown={onKeyDown}
            onClick={onClick}
            value={nativeValue}
            placeholder={placeholder}
            errorMessage={errorMessage}
            layoutProps={layoutProps}
            inputClassName="font-mono"
        />
    )
}

export function InputText({ type = "text", label, value, onChange, onChangeNative = false, placeholder, errorMessage = null, layoutProps = {}, inputClassName, ...props }) {
    const id = useId()
    return (
        <BaseLayout id={id} label={label} errorMessage={errorMessage} {...layoutProps}>
            <input
                type={type}
                value={value}
                onChange={onChangeNative ? onChange : ({ target: { value } }) => onChange(value)}
                name={id}
                className={classNames(
                    "block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm",
                    inputClassName
                )}
                placeholder={placeholder}
                {...props}
            />

            {errorMessage && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <IconSolid.ExclamationCircleIcon className="h-5 w-5 text-rose-500" aria-hidden="true" />
                </div>
            )}
        </BaseLayout>

    )
}
export function InputTextArea({ label, value, onChange, placeholder, rows = 5, errorMessage = null }) {
    const id = useId()
    return (
        <BaseLayout id={id} label={label} errorMessage={errorMessage}>
            <textarea
                name={id}
                rows={rows}
                value={value}
                onChange={({ target: { value } }) => onChange(value)}
                className="w-full shadow-sm block focus:ring-0 focus:border-0 sm:text-sm border-0 ring-0 rounded-md"
                defaultValue={''}
                placeholder={placeholder}
            />
        </BaseLayout>
    )
}

export function InputSecure({ label, value, onChange, placeholder, errorMessage }) {
    const [showPassword, setShowPassword] = useState(false)

    return <InputText
        type={showPassword ? 'text' : 'password'}
        layoutProps={{
            postFixContent: (
                <div onMouseUp={() => setShowPassword(false)} onMouseDown={() => setShowPassword(true)} className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    {
                        showPassword ?
                            <IconSolid.EyeSlashIcon className="h-5 w-5" aria-hidden="true" /> :
                            <IconSolid.EyeIcon className="h-5 w-5" aria-hidden="true" />
                    }
                </div>
            )
        }}

        {...{ label, value, onChange, placeholder, errorMessage }}
    />
}

export function InputNumber({ label, value, onChange, placeholder, errorMessage = null, layoutProps, }) {
    return <InputText
        type="number"
        {...{ label, value, onChange, placeholder, errorMessage, layoutProps, inputClassName: "font-mono" }}
    />
}

function BaseLayout({ id, label, children, postFixContent = null, preFixContent = null, errorMessage = null, }) {
    return (
        <div>
            <div className={classNames(
                "relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 ",
                errorMessage ? "border-red-300 text-red-900 focus-within:ring-rose-600 focus-within:border-rose-600" : "focus-within:ring-indigo-600 focus-within:border-indigo-600"
            )}>
                <label
                    htmlFor={id}
                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                >
                    {label}
                </label>
                {preFixContent}
                <span>{children}</span>
                {postFixContent}
            </div>
            <p className="mt-2 text-sm text-red-600">
                {errorMessage}
            </p>
        </div>
    )
}

export function Selection({ label, value, onChange, placeholder, labelSelector, useQuery, errorMessage = null }) {
    const id = useId()
    const [keyword, setKeyword] = useState('')
    const debounceKeyword = useDebounce(keyword, 500);
    const browse = useQuery({ keyword: debounceKeyword })
    const items = transformResponseToList(browse.data)

    return (
        <BaseLayout id={id} label={label} errorMessage={errorMessage}>
            <Combobox value={value} onChange={onChange}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left sm:text-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={labelSelector}
                            onChange={({ target: { value } }) => setKeyword(value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <IconSolid.ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setKeyword('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {items.length === 0 && keyword !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                items.map((item) => (
                                    <Combobox.Option
                                        key={item.ID}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {labelSelector(item)}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                            }`}
                                                    >
                                                        <IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </BaseLayout>

    )
}