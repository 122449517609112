import { Fragment } from "react"
import ContentLoader from "react-content-loader"
import { generateArray } from "Utility/generator"

export default function TableLoader({ rowSize = 3, columnSize = 5 }) {
    return (
        <Fragment>
            {generateArray(rowSize).map((_, index) => <RowLoader key={index} columnSize={columnSize} />)}
        </Fragment>
    )
}

const RowLoader = ({ columnSize }) => (
    <tr>
        {generateArray(columnSize).map((_, index) => (
            <td key={index} className="px-6 py-4 whitespace-nowrap">
                <ColumnLoader />
            </td>
        ))}
    </tr>
)


const ColumnLoader = (props) => (
    <ContentLoader
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 40 30"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="3" y="6" rx="3" ry="3" width="20" height="6" />
        <rect x="3" y="20" rx="3" ry="3" width="20" height="6" />
        <rect x="5" y="32" rx="3" ry="3" width="20" height="6" />
    </ContentLoader>
)