
import jwt_decode from "jwt-decode";

const KEY_AUTHENTICATION = 'authentication-key'
/**
 * This represents some generic auth provider API, like Firebase.
 */
const authenticationProvider = {
    in(token, callback) {
        localStorage.setItem(KEY_AUTHENTICATION, token)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    out(callback) {
        localStorage.removeItem(KEY_AUTHENTICATION)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    authenticated() {
        return this.token() !== null
    },
    token() {
        return localStorage.getItem(KEY_AUTHENTICATION)
    },
    role() {
        const token = this.token()
        if(token === null) {
            return null
        }
        const payload = jwt_decode(token)
        return payload.data.role
    }
};

export { authenticationProvider, KEY_AUTHENTICATION };
