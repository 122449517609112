import { requestGet, requestPost } from "Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useBrowseBank({ sort = 'code', order = 'asc', expands = [], pageSize = 20, currentPage = 1, keyword, onSuccess }) {
    const path = `/organization/bank`
    const data = useQuery([...(path.split('/')), sort, order, pageSize, keyword, currentPage], () => requestGet(path, { sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
