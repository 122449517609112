import { Fragment } from "react";
import * as IconOutline from '@heroicons/react/24/outline'
import * as IconSolid from '@heroicons/react/24/solid'
import SidebarMenu from "./SidebarMenu";
import SidebarMenuGroup from "./SidebarMenuGroup";
import { authenticationProvider } from "Provider/Authentication";
import { LineSegment, LineSegments, TreeStructure } from "@phosphor-icons/react";

export default function NavigationPanel() {
    return (
        <Fragment>
            <SidebarMenu to='/' icons={[IconOutline.HomeIcon, IconSolid.HomeIcon]} name="Dashboard" />

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">Transaksi</span>
                </div>
            </div>
            <SidebarMenu to='/live' icons={[IconOutline.SignalIcon, IconSolid.SignalIcon]} name="Live" />
            <SidebarMenu to='/delivery' icons={[IconOutline.CalendarDaysIcon, IconSolid.CalendarDaysIcon]} name="Pengiriman" />


            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">Laporan</span>
                </div>
            </div>

            <SidebarMenu to='/report/millage?status=active' icons={[IconOutline.RocketLaunchIcon, IconSolid.RocketLaunchIcon]} name="Millage" />

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">Organisasi</span>
                </div>
            </div>
            {authenticationProvider.role() !== 'Super-Administrator' ? null :
                <Fragment>
                    <SidebarMenuGroup to='/employee' icons={[IconOutline.IdentificationIcon, IconSolid.IdentificationIcon]} name="Karyawan"
                        items={[
                            { to: '/employee', name: 'Jelajah Karyawan', icons: [IconOutline.RectangleStackIcon, IconSolid.RectangleStackIcon] },
                            { to: '/employee/create', name: 'Buat Baru Karyawan', icons: [IconOutline.PlusCircleIcon, IconSolid.PlusCircleIcon] },
                        ]}
                    />
                    <SidebarMenuGroup to='/employee-position' icons={[TreeStructure, props => <TreeStructure {...props} weight="fill" />]} name="Struktur Jabatan"
                        items={[
                            { to: '/employee-position', name: 'Posisi', icons: [LineSegment, props => <LineSegment {...props} weight="fill" />] },
                            { to: '/employee-position/group', name: 'Grup Posisi', icons: [LineSegments, props => <LineSegments {...props} weight="fill" />] },
                        ]}
                    />
                    <SidebarMenuGroup to='/courier' icons={[IconOutline.IdentificationIcon, IconSolid.IdentificationIcon]} name="Kurir"
                        items={[
                            { to: '/courier', name: 'Jelajah Kurir', icons: [IconOutline.RectangleStackIcon, IconSolid.RectangleStackIcon] },
                            { to: '/courier/create', name: 'Buat Baru Kurir', icons: [IconOutline.PlusCircleIcon, IconSolid.PlusCircleIcon] },
                        ]}
                    />
                </Fragment>
            }
            <SidebarMenu to='/employee-attendance-log' icons={[IconOutline.ClockIcon, IconSolid.ClockIcon]} name="Presensi" />

            {authenticationProvider.role() !== 'Super-Administrator' ? null :
                <Fragment>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-500">Master</span>
                        </div>
                    </div>

                    <SidebarMenu to='/account' icons={[IconOutline.UserGroupIcon, IconSolid.UserGroupIcon]} name="Access" />
                </Fragment>
            }
        </Fragment>
    )
}