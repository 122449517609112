import { createContext, useContext } from "react"
import { useParams } from "react-router-dom"
import { transformResponseToSingle } from "Utility/formatter"
import { useDetailDelivery } from "../../Data/api"

const DetailDeliveryContext = createContext()

export function DetailDeliveryProvider({ children }) {
    const { id } = useParams()
    const detailDelivery = useDetailDelivery(id)
    const delivery = transformResponseToSingle(detailDelivery.data)


    const shared = {
        id,
        detailDelivery, delivery

    }
    return (
        <DetailDeliveryContext.Provider value={shared}>
            {children}
        </DetailDeliveryContext.Provider>
    )
}

export function useDetailDeliveryContext() {
    return useContext(DetailDeliveryContext)
}
