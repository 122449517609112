import React, { Fragment } from "react"
import classNames from "classnames"
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom"




export function TabViewRoutes({ basePath, routes = [] }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()


    return (
        <div>
            <div className='mt-10'>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Pindah ke
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        value={pathname}
                        onChange={({ target: { value } }) => navigate(value)}
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    >
                        {routes.map(({ to, label, labelSR }) =>
                            <option key={`${basePath}${to}`} value={`${basePath}${to}`} className="">
                                {labelSR ? labelSR : label}
                            </option>
                        )}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {routes.map(({ to, icon, label }) =>
                                <NavLink to={`${basePath}${to}`} end key={to}
                                    className={({ isActive }) => classNames(
                                        isActive
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                >
                                    {({ isActive }) => (
                                        <Fragment>
                                            {isActive ?
                                                <icon.solid
                                                    className={classNames(
                                                        isActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-0.5 mr-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                /> :
                                                <icon.outline
                                                    className={classNames(
                                                        isActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-0.5 mr-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            }
                                            <span>
                                                {label}
                                            </span>
                                        </Fragment>
                                    )}
                                </NavLink>
                            )}
                        </nav>
                    </div>
                </div>
            </div>
            <Routes>
                {routes.map(({ to, element }) =>
                    <Route
                        key={to}
                        path={to}
                        element={React.createElement(element)} />
                )}
            </Routes>
        </div>
    )
}